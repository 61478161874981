import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import VisualCompass from './components/VisualCompass';
import AIStrategyGenerator from './components/AIStrategyGenerator';
import EthicalAISimulator from './components/EthicalAISimulator';
import GovernanceMatrix from './components/GovernanceMatrix';
import AIReadinessAssessment from './components/AIReadinessAssessment';
import { Compass, FileText, Shield, Grid, BarChart2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import './components.css';

type ToolType = 'compass' | 'strategy-generator' | 'simulator' | 'matrix' | 'assessment';

interface AIReadinessScore {
  overallScore: number;
  categoryScores: {
    [key: string]: number;
  };
}

interface AIGuruMasterySuiteProps {
  signOut?: () => void;
  user?: {
    username?: string;
    attributes?: {
      email?: string;
    };
  };
}

const AIGuruMasterySuite: React.FC<AIGuruMasterySuiteProps> = ({ signOut, user }) => {
  const [activeTool, setActiveTool] = useState<ToolType>('assessment');
  const [userName, setUserName] = useState<string>('Guest');
  const [aiReadinessScore, setAiReadinessScore] = useState<AIReadinessScore | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      setUserName(user.username || 'Guest');
    }
  }, [user]);

  useEffect(() => {
    const state = location.state as { action?: 'signIn' | 'signUp' };
    if (state?.action === 'signUp') {
      // You can add logic here to show the sign-up form or trigger sign-up flow
      console.log('User wants to sign up');
    }
  }, [location]);


  const handleAssessmentUpdate = (data: { [key: string]: number }) => {
    console.log('Assessment updated:', data);
    const overallScore = Object.values(data).reduce((sum, score) => sum + score, 0) / Object.keys(data).length;
    const aiReadinessScore: AIReadinessScore = {
      overallScore,
      categoryScores: data
    };
    setAiReadinessScore(aiReadinessScore);
  };

  const handleSignOut = () => {
    if (signOut) {
      signOut();
    }
    navigate('/');
  };

  const renderActiveTool = () => {
    switch (activeTool) {
      case 'compass':
        return <VisualCompass />;
      case 'strategy-generator':
        return aiReadinessScore ? (
          <AIStrategyGenerator aiReadinessScore={aiReadinessScore} />
        ) : (
          <div>Please complete the AI Readiness Assessment first.</div>
        );
      case 'simulator':
        return <EthicalAISimulator />;
      case 'matrix':
        return <GovernanceMatrix />;
      case 'assessment':
        return <AIReadinessAssessment onAssessmentUpdate={handleAssessmentUpdate} />;
      default:
        return <AIReadinessAssessment onAssessmentUpdate={handleAssessmentUpdate} />;
    }
  };

  const tools = [
    { type: 'assessment', title: 'AI Readiness Assessment', icon: BarChart2 },
    { type: 'compass', title: 'V.I.S.U.A.L. Compass', icon: Compass },
    { type: 'strategy-generator', title: 'AI Strategy Generator', icon: FileText },
    { type: 'simulator', title: 'EthicalAI Simulator', icon: Shield },
    { type: 'matrix', title: 'Governance Matrix', icon: Grid },
  ];

return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
    <header className="bg-white bg-opacity-10 p-6 flex justify-between items-center">
        <Link to="/" className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 hover:opacity-80 transition-opacity">
          AI Strategy Suite
        </Link>
            <div className="flex items-center">
          <span className="mr-4">Welcome, {user?.username || 'Guest'}</span>
          {signOut && (
            <button
              className="px-4 py-2 bg-gradient-to-r from-red-500 to-pink-500 rounded-full font-semibold hover:from-red-600 hover:to-pink-600 transition-colors duration-300"
              onClick={signOut}
            >
              Sign out
            </button>
          )}
        </div>
	</header>

      <main className="container mx-auto px-4 py-8">
        <nav className="mb-8">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {tools.map((tool) => (
              <button
                key={tool.type}
                className={`flex items-center justify-center p-4 rounded-lg transition-colors duration-300 ${
                  activeTool === tool.type
                    ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white'
                    : 'bg-white bg-opacity-10 hover:bg-opacity-20'
                }`}
                onClick={() => setActiveTool(tool.type as ToolType)}
              >
                <tool.icon className="mr-2" size={20} />
                <span>{tool.title}</span>
              </button>
            ))}
          </div>
        </nav>

        <div className="bg-white bg-opacity-10 rounded-lg p-6">
          {renderActiveTool()}
        </div>
      </main>
    </div>
  );
};

export default AIGuruMasterySuite;

