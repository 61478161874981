import React from 'react';
import { Link } from 'react-router-dom';
import { CheckSquare, Database, Cpu, Users, Lightbulb, FileText, BarChart2, DollarSign } from 'lucide-react';

const AIReadinessAssessmentPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-green-900 to-teal-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-500">
          AI Readiness Assessment
        </h1>
        <Link 
          to="/" 
          className="px-4 py-2 bg-green-600 hover:bg-green-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/images/A_sleek_modern_device_800x600.png"
            alt="AI Readiness Assessment"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Evaluate and Enhance Your AI Capabilities</h2>
            <p className="text-lg mb-6">
              Our AI Readiness Assessment is a comprehensive tool designed to evaluate your organization's 
              preparedness for AI adoption and implementation. By assessing key areas crucial for AI success, 
              we provide you with actionable insights to enhance your AI capabilities and drive your digital 
              transformation forward.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">Assessment Categories:</h3>
              <ul className="space-y-2">
                <li><strong>Data Readiness:</strong> Evaluate data availability, quality, and accessibility</li>
                <li><strong>Technology Infrastructure:</strong> Assess your technical capabilities for AI</li>
                <li><strong>Workforce Capability:</strong> Gauge your team's AI skills and adaptability</li>
                <li><strong>Organizational Culture:</strong> Measure openness to AI adoption and innovation</li>
                <li><strong>Governance and Ethics:</strong> Evaluate AI policy and ethical preparedness</li>
                <li><strong>Business Process Alignment:</strong> Assess AI integration with business processes</li>
                <li><strong>Leadership and Strategy:</strong> Gauge leadership support and strategic alignment</li>
                <li><strong>Financial Readiness:</strong> Evaluate budget allocation and ROI expectations</li>
              </ul>
            </div>
          </div>
        </div>

        <h3 className="text-2xl font-bold mb-6">Key Features</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { icon: CheckSquare, title: "Comprehensive Questionnaire", description: "In-depth assessment covering all aspects of AI readiness" },
            { icon: Database, title: "Data Readiness Evaluation", description: "Assess your data quality and availability for AI projects" },
            { icon: Cpu, title: "Tech Infrastructure Analysis", description: "Evaluate your current technology stack for AI capabilities" },
            { icon: Users, title: "Workforce Capability Assessment", description: "Gauge your team's AI skills and identify training needs" },
            { icon: Lightbulb, title: "Cultural Readiness Insights", description: "Understand your organization's openness to AI adoption" },
            { icon: FileText, title: "Governance Preparedness Check", description: "Evaluate your AI policies and ethical guidelines" },
            { icon: BarChart2, title: "Customized Readiness Score", description: "Receive a detailed score across various AI readiness dimensions" },
            { icon: DollarSign, title: "Financial Planning Guide", description: "Get insights on budgeting and ROI planning for AI initiatives" }
          ].map((feature, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
              <feature.icon size={48} className="mb-4 text-green-300" />
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-green-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why Assess Your AI Readiness?</h3>
          <p className="text-lg mb-6">
            Understanding your organization's AI readiness is crucial for successful AI adoption and implementation. 
            Our assessment helps you identify strengths and areas for improvement, allowing you to make informed 
            decisions, allocate resources effectively, and develop a tailored roadmap for AI success. By evaluating 
            your readiness now, you can avoid potential pitfalls and accelerate your AI journey.
          </p>
          <Link 
            to="/dashboard" 
            className="inline-block px-6 py-3 bg-gradient-to-r from-teal-500 to-green-500 rounded-full font-semibold hover:from-teal-600 hover:to-green-600 transition-colors duration-300"
          >
            Start Your AI Readiness Assessment Now
          </Link>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold mb-6">Assessment Process</h3>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg">
            <ol className="space-y-4">
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">1.</span>
                <div>
                  <h4 className="font-semibold text-xl">Questionnaire Completion</h4>
                  <p>Answer a series of targeted questions across various AI readiness dimensions.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">2.</span>
                <div>
                  <h4 className="font-semibold text-xl">Automated Analysis</h4>
                  <p>Our system analyzes your responses to generate a comprehensive readiness profile.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">3.</span>
                <div>
                  <h4 className="font-semibold text-xl">Readiness Score Generation</h4>
                  <p>Receive a detailed score breakdown across different AI readiness categories.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">4.</span>
                <div>
                  <h4 className="font-semibold text-xl">Strengths and Gaps Identification</h4>
                  <p>Understand your organization's AI strengths and areas needing improvement.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">5.</span>
                <div>
                  <h4 className="font-semibold text-xl">Recommendations</h4>
                  <p>Receive tailored recommendations to enhance your AI readiness.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">6.</span>
                <div>
                  <h4 className="font-semibold text-xl">Action Plan Development</h4>
                  <p>Use the insights to create a strategic action plan for AI adoption and implementation.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AIReadinessAssessmentPage;
