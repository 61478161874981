import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import './index.css';
import './components.css';
import App from './App';

Amplify.configure(awsExports);

const theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '{colors.blue.10}',
          20: '{colors.blue.20}',
          40: '{colors.blue.40}',
          60: '{colors.blue.60}',
          80: '{colors.blue.80}',
          90: '{colors.blue.90}',
          100: '{colors.blue.100}',
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: '{colors.brand.primary.60}',
          _hover: {
            backgroundColor: '{colors.brand.primary.80}',
          },
        },
      },
    },
  },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

