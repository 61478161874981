import React from 'react';
import { Link } from 'react-router-dom';
import { Map, Layers, Target, Zap, Users, TrendingUp } from 'lucide-react';

const StrategyBlueprintPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-green-900 to-teal-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-500">
          Strategy Blueprint
        </h1>
        <Link 
          to="/" 
          className="px-4 py-2 bg-green-600 hover:bg-green-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/images/An_intricate_3D_hologram_800x600.png"
            alt="Strategy Blueprint"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Design Your AI Roadmap for Success</h2>
            <p className="text-lg mb-6">
              The Strategy Blueprint is your architect for AI success. It provides a structured approach to 
              designing, implementing, and evolving your organization's AI strategy. From initial concept to 
              full-scale deployment, our blueprint ensures your AI initiatives are aligned with your business 
              objectives and poised for success.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">The Blueprint Process:</h3>
              <ol className="space-y-2 list-decimal list-inside">
                <li>Assess Current State</li>
                <li>Define AI Vision and Goals</li>
                <li>Identify Key Opportunities</li>
                <li>Develop Implementation Roadmap</li>
                <li>Allocate Resources</li>
                <li>Execute and Monitor</li>
                <li>Iterate and Optimize</li>
              </ol>
            </div>
          </div>
        </div>

        <h3 className="text-2xl font-bold mb-6">Key Features</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { icon: Map, title: "Strategic Mapping", description: "Visualize your AI journey from start to finish" },
            { icon: Layers, title: "Phased Approach", description: "Break down your strategy into manageable stages" },
            { icon: Target, title: "Goal Alignment", description: "Ensure AI initiatives support business objectives" },
            { icon: Zap, title: "Quick Wins Identification", description: "Spot opportunities for rapid AI implementation" },
            { icon: Users, title: "Stakeholder Engagement", description: "Tools to involve key players in strategy development" },
            { icon: TrendingUp, title: "Progress Tracking", description: "Monitor and report on your AI strategy execution" }
          ].map((feature, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
              <feature.icon size={48} className="mb-4 text-green-300" />
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-green-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why Use Strategy Blueprint?</h3>
          <p className="text-lg mb-6">
            In the dynamic world of AI, a well-crafted strategy is your compass and roadmap combined. 
            The Strategy Blueprint doesn't just help you plan—it empowers you to execute with precision, 
            adapt with agility, and achieve measurable results. Whether you're just starting your AI 
            journey or looking to scale existing initiatives, our blueprint provides the structure and 
            insights you need to succeed.
          </p>
          <Link 
            to="/dashboard" 
            className="inline-block px-6 py-3 bg-gradient-to-r from-teal-500 to-green-500 rounded-full font-semibold hover:from-teal-600 hover:to-green-600 transition-colors duration-300"
          >
            Create Your AI Blueprint Now
          </Link>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold mb-6">How It Works</h3>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg">
            <ol className="space-y-4">
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">1.</span>
                <div>
                  <h4 className="font-semibold text-xl">Assessment</h4>
                  <p>We begin by thoroughly assessing your current AI capabilities, business needs, and market position.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">2.</span>
                <div>
                  <h4 className="font-semibold text-xl">Strategy Formulation</h4>
                  <p>Based on the assessment, we help you formulate a comprehensive AI strategy aligned with your business goals.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">3.</span>
                <div>
                  <h4 className="font-semibold text-xl">Roadmap Creation</h4>
                  <p>We develop a detailed roadmap, breaking down your strategy into actionable phases and milestones.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">4.</span>
                <div>
                  <h4 className="font-semibold text-xl">Implementation Planning</h4>
                  <p>We help you plan the implementation, including resource allocation, timeline setting, and risk management.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">5.</span>
                <div>
                  <h4 className="font-semibold text-xl">Execution Support</h4>
                  <p>Our tool provides ongoing support during the execution phase, helping you track progress and make data-driven decisions.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">6.</span>
                <div>
                  <h4 className="font-semibold text-xl">Continuous Optimization</h4>
                  <p>We help you continuously monitor, evaluate, and refine your strategy to ensure long-term success in your AI initiatives.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StrategyBlueprintPage;
