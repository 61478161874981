import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Users, Scale, Eye, Zap, RefreshCw } from 'lucide-react';

const EthicalAISimulatorPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-purple-900 to-indigo-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-500">
          Ethical AI Simulator
        </h1>
        <Link 
          to="/" 
          className="px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/images/A_glowing_translucent_cube_800x600.png"
            alt="Ethical AI Simulator"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Simulate and Ensure Ethical AI Practices</h2>
            <p className="text-lg mb-6">
              The Ethical AI Simulator is your compass for navigating the complex landscape of AI ethics. 
              It provides a safe environment to simulate AI decision-making scenarios, identify potential 
              ethical pitfalls, and develop robust strategies for implementing responsible AI practices 
              in your organization.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">Key Ethical Principles Covered:</h3>
              <ul className="space-y-2">
                <li><strong>Fairness:</strong> Ensure AI systems treat all individuals and groups equitably</li>
                <li><strong>Transparency:</strong> Make AI decision-making processes clear and explainable</li>
                <li><strong>Privacy:</strong> Protect personal data and respect individual privacy rights</li>
                <li><strong>Accountability:</strong> Establish clear lines of responsibility for AI systems</li>
                <li><strong>Safety:</strong> Ensure AI systems do not cause harm to humans or the environment</li>
                <li><strong>Human Oversight:</strong> Maintain human control over AI systems</li>
              </ul>
            </div>
          </div>
        </div>

  <h3 className="text-2xl font-bold mb-6">Key Features</h3>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    {[
      { icon: Shield, title: "Ethical Risk Assessment", description: "Identify potential ethical risks in your AI systems" },
      { icon: Users, title: "Stakeholder Impact Analysis", description: "Evaluate how AI decisions affect different user groups" },
      { icon: Scale, title: "Fairness Metrics", description: "Measure and improve the fairness of your AI algorithms" },
      { icon: Eye, title: "Transparency Simulator", description: "Test and enhance the explainability of AI decisions" },
      { icon: Zap, title: "Ethical Dilemma Scenarios", description: "Navigate complex ethical situations in a safe environment" },
      { icon: RefreshCw, title: "Continuous Ethics Monitoring", description: "Implement ongoing ethical checks in your AI pipeline" }
    ].map((feature, index) => (
      <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
        <feature.icon size={48} className="mb-4 text-purple-300" />
        <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
        <p>{feature.description}</p>
      </div>
    ))}
  </div>

        <div className="mt-12 bg-purple-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why Ethical AI Matters</h3>
          <p className="text-lg mb-6">
            In today's AI-driven world, ethical considerations are not just a moral imperative—they're a 
            business necessity. Ethical AI practices build trust with users, mitigate risks, ensure regulatory 
            compliance, and create long-term sustainable value. Our Ethical AI Simulator empowers you to 
            lead in responsible AI innovation, setting you apart in an increasingly AI-focused market.
          </p>
          <Link 
            to="/dashboard" 
            className="inline-block px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-500 rounded-full font-semibold hover:from-pink-600 hover:to-purple-600 transition-colors duration-300"
          >
            Start Simulating Ethical AI Now
          </Link>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold mb-6">How It Works</h3>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg">
            <ol className="space-y-4">
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">1.</span>
                <div>
                  <h4 className="font-semibold text-xl">Scenario Generation</h4>
                  <p>Our system creates realistic AI decision-making scenarios based on your industry and use cases.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">2.</span>
                <div>
                  <h4 className="font-semibold text-xl">Ethical Analysis</h4>
                  <p>The simulator evaluates each scenario against key ethical principles, highlighting potential issues.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">3.</span>
                <div>
                  <h4 className="font-semibold text-xl">Decision Simulation</h4>
                  <p>Users can make decisions in these scenarios and see the ethical implications play out.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">4.</span>
                <div>
                  <h4 className="font-semibold text-xl">Impact Assessment</h4>
                  <p>The system provides a detailed analysis of the decisions' impacts on various stakeholders.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">5.</span>
                <div>
                  <h4 className="font-semibold text-xl">Recommendations</h4>
                  <p>Based on the simulation results, the tool offers actionable recommendations for ethical AI implementation.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">6.</span>
                <div>
                  <h4 className="font-semibold text-xl">Continuous Learning</h4>
                  <p>The simulator learns from each interaction, continuously improving its ability to identify and address ethical concerns.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EthicalAISimulatorPage;
