import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Shield, FileText, Users, Target, BarChart2 } from 'lucide-react';

const GovernanceMatrixPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-blue-900 to-cyan-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-500">
          AI Governance Matrix
        </h1>
        <Link 
          to="/" 
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/images/A_digital_dashboard_800x600.png"
            alt="AI Governance Matrix"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Implement Robust AI Governance</h2>
            <p className="text-lg mb-6">
              The AI Governance Matrix is your comprehensive tool for establishing, implementing, and 
              maintaining robust AI governance in your organization. It provides a structured approach 
              to managing AI risks, ensuring compliance, and fostering responsible AI practices across 
              all levels of your enterprise.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">Key Governance Areas:</h3>
              <ul className="space-y-2">
                <li><strong>Risk Management:</strong> Identify and mitigate AI-related risks</li>
                <li><strong>Ethical Compliance:</strong> Ensure adherence to ethical AI principles</li>
                <li><strong>Data Governance:</strong> Manage data quality, privacy, and security</li>
                <li><strong>Operational Excellence:</strong> Optimize AI processes and workflows</li>
                <li><strong>Regulatory Compliance:</strong> Stay aligned with AI regulations and standards</li>
                <li><strong>Stakeholder Management:</strong> Engage and communicate with all AI stakeholders</li>
              </ul>
            </div>
          </div>
        </div>

        <h3 className="text-2xl font-bold mb-6">Key Features</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { icon: Grid, title: "Governance Framework", description: "Comprehensive matrix covering all aspects of AI governance" },
            { icon: Shield, title: "Risk Assessment Tools", description: "Identify and analyze AI-related risks across your organization" },
            { icon: FileText, title: "Policy Generator", description: "Create tailored AI governance policies and guidelines" },
            { icon: Users, title: "Stakeholder Dashboard", description: "Manage and communicate with all AI project stakeholders" },
            { icon: Target, title: "Compliance Tracker", description: "Monitor and ensure adherence to AI regulations and standards" },
            { icon: BarChart2, title: "Performance Metrics", description: "Measure and optimize your AI governance effectiveness" }
          ].map((feature, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
              <feature.icon size={48} className="mb-4 text-cyan-300" />
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-blue-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why AI Governance Matters</h3>
          <p className="text-lg mb-6">
            In an era where AI is becoming increasingly prevalent, robust governance is not just a 
            nice-to-have—it's a necessity. Effective AI governance helps organizations minimize risks, 
            build trust with stakeholders, ensure regulatory compliance, and maximize the value of their 
            AI investments. Our AI Governance Matrix empowers you to take control of your AI initiatives, 
            ensuring they align with your organizational values and strategic objectives.
          </p>
          <Link 
            to="/dashboard" 
            className="inline-block px-6 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full font-semibold hover:from-cyan-600 hover:to-blue-600 transition-colors duration-300"
          >
            Start Building Your AI Governance Now
          </Link>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold mb-6">How It Works</h3>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg">
            <ol className="space-y-4">
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">1.</span>
                <div>
                  <h4 className="font-semibold text-xl">Assessment</h4>
                  <p>Evaluate your current AI governance maturity across key dimensions.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">2.</span>
                <div>
                  <h4 className="font-semibold text-xl">Gap Analysis</h4>
                  <p>Identify areas where your AI governance needs improvement.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">3.</span>
                <div>
                  <h4 className="font-semibold text-xl">Policy Development</h4>
                  <p>Generate customized AI governance policies tailored to your organization.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">4.</span>
                <div>
                  <h4 className="font-semibold text-xl">Implementation Planning</h4>
                  <p>Create a roadmap for implementing robust AI governance practices.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">5.</span>
                <div>
                  <h4 className="font-semibold text-xl">Monitoring and Reporting</h4>
                  <p>Track governance metrics and generate reports for stakeholders.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">6.</span>
                <div>
                  <h4 className="font-semibold text-xl">Continuous Improvement</h4>
                  <p>Regularly reassess and refine your AI governance framework.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default GovernanceMatrixPage;
