import React from 'react';
import { Link } from 'react-router-dom';
import { Lightbulb, Target, BarChart, PieChart, Layers, Zap } from 'lucide-react';

const UseCaseIdentificationPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-blue-900 to-indigo-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-500">
          AI Use Case Identification
        </h1>
        <Link
          to="/"
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/api/placeholder/800/600"
            alt="AI Use Case Identification"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Discover Transformative AI Opportunities</h2>
            <p className="text-lg mb-6">
              Our AI Use Case Identification tool helps you uncover high-impact opportunities 
              to leverage artificial intelligence within your organization. By analyzing your 
              business processes, challenges, and goals, we guide you towards the most promising 
              AI applications tailored to your industry and specific needs.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">Key Benefits:</h3>
              <ul className="space-y-2">
                <li><strong>Strategic Alignment:</strong> Identify AI use cases that directly support your business objectives</li>
                <li><strong>Efficiency Gains:</strong> Uncover opportunities to streamline operations and reduce costs</li>
                <li><strong>Innovation Catalyst:</strong> Discover new ways to enhance products, services, and customer experiences</li>
                <li><strong>Competitive Edge:</strong> Stay ahead of the curve by leveraging cutting-edge AI technologies</li>
                <li><strong>ROI Maximization:</strong> Focus on high-impact use cases with the greatest potential return</li>
              </ul>
            </div>
          </div>
        </div>

        <h3 className="text-2xl font-bold mb-6">Our Approach</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { icon: Lightbulb, title: "Ideation Workshops", description: "Collaborative sessions to brainstorm potential AI applications" },
            { icon: Target, title: "Opportunity Mapping", description: "Systematically identify pain points and areas for improvement" },
            { icon: BarChart, title: "Impact Assessment", description: "Evaluate potential AI use cases based on business value and feasibility" },
            { icon: PieChart, title: "Prioritization Framework", description: "Rank use cases to determine the most promising opportunities" },
            { icon: Layers, title: "Industry Benchmarking", description: "Compare your AI initiatives with industry best practices" },
            { icon: Zap, title: "Quick-win Identification", description: "Highlight low-hanging fruit for rapid AI implementation" }
          ].map((feature, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
              <feature.icon size={48} className="mb-4 text-blue-300" />
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-blue-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why AI Use Case Identification Matters</h3>
          <p className="text-lg mb-6">
            In the rapidly evolving landscape of AI, identifying the right use cases is crucial for 
            successful adoption and implementation. Our structured approach ensures that you focus 
            your resources on AI initiatives that will drive the most value for your organization, 
            avoiding costly missteps and accelerating your digital transformation journey.
          </p>
          <Link
            to="/dashboard"
            className="inline-block px-6 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full font-semibold hover:from-cyan-600 hover:to-blue-600 transition-colors duration-300"
          >
            Start Identifying AI Use Cases Now
          </Link>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold mb-6">Our Process</h3>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg">
            <ol className="space-y-4">
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">1.</span>
                <div>
                  <h4 className="font-semibold text-xl">Business Analysis</h4>
                  <p>We conduct a thorough assessment of your current operations, challenges, and strategic goals.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">2.</span>
                <div>
                  <h4 className="font-semibold text-xl">Use Case Discovery</h4>
                  <p>Our experts work with your team to identify potential AI applications across various departments and processes.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">3.</span>
                <div>
                  <h4 className="font-semibold text-xl">Feasibility Study</h4>
                  <p>We assess the technical feasibility and data requirements for each identified use case.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">4.</span>
                <div>
                  <h4 className="font-semibold text-xl">Impact Evaluation</h4>
                  <p>Our team analyzes the potential business impact and ROI for each use case.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">5.</span>
                <div>
                  <h4 className="font-semibold text-xl">Prioritization</h4>
                  <p>We help you prioritize use cases based on their potential impact, feasibility, and alignment with your strategic objectives.</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-bold text-2xl mr-4">6.</span>
                <div>
                  <h4 className="font-semibold text-xl">Roadmap Development</h4>
                  <p>We create a comprehensive roadmap for implementing the selected AI use cases in your organization.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UseCaseIdentificationPage;
