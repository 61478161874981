/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSkill = /* GraphQL */ `query GetSkill($id: ID!) {
  getSkill(id: $id) {
    id
    name
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSkillQueryVariables, APITypes.GetSkillQuery>;
export const listSkills = /* GraphQL */ `query ListSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      value
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillsQueryVariables,
  APITypes.ListSkillsQuery
>;
export const getStrategy = /* GraphQL */ `query GetStrategy($id: ID!) {
  getStrategy(id: $id) {
    id
    userId
    visionId
    goals
    actions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStrategyQueryVariables,
  APITypes.GetStrategyQuery
>;
export const listStrategies = /* GraphQL */ `query ListStrategies(
  $filter: ModelStrategyFilterInput
  $limit: Int
  $nextToken: String
) {
  listStrategies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      visionId
      goals
      actions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStrategiesQueryVariables,
  APITypes.ListStrategiesQuery
>;
export const getEthicalScenario = /* GraphQL */ `query GetEthicalScenario($id: ID!) {
  getEthicalScenario(id: $id) {
    id
    description
    options
    correctOption
    explanation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEthicalScenarioQueryVariables,
  APITypes.GetEthicalScenarioQuery
>;
export const listEthicalScenarios = /* GraphQL */ `query ListEthicalScenarios(
  $filter: ModelEthicalScenarioFilterInput
  $limit: Int
  $nextToken: String
) {
  listEthicalScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      description
      options
      correctOption
      explanation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEthicalScenariosQueryVariables,
  APITypes.ListEthicalScenariosQuery
>;
export const getGovernanceItem = /* GraphQL */ `query GetGovernanceItem($id: ID!) {
  getGovernanceItem(id: $id) {
    id
    area
    policy
    implementation
    compliance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGovernanceItemQueryVariables,
  APITypes.GetGovernanceItemQuery
>;
export const listGovernanceItems = /* GraphQL */ `query ListGovernanceItems(
  $filter: ModelGovernanceItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listGovernanceItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      area
      policy
      implementation
      compliance
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGovernanceItemsQueryVariables,
  APITypes.ListGovernanceItemsQuery
>;
export const getAssessmentQuestion = /* GraphQL */ `query GetAssessmentQuestion($id: ID!) {
  getAssessmentQuestion(id: $id) {
    id
    question
    category
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssessmentQuestionQueryVariables,
  APITypes.GetAssessmentQuestionQuery
>;
export const listAssessmentQuestions = /* GraphQL */ `query ListAssessmentQuestions(
  $filter: ModelAssessmentQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssessmentQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      question
      category
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssessmentQuestionsQueryVariables,
  APITypes.ListAssessmentQuestionsQuery
>;
export const getAssessmentResult = /* GraphQL */ `query GetAssessmentResult($id: ID!) {
  getAssessmentResult(id: $id) {
    id
    userId
    category
    score
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssessmentResultQueryVariables,
  APITypes.GetAssessmentResultQuery
>;
export const listAssessmentResults = /* GraphQL */ `query ListAssessmentResults(
  $filter: ModelAssessmentResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssessmentResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      category
      score
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssessmentResultsQueryVariables,
  APITypes.ListAssessmentResultsQuery
>;
export const getUserAssessment = /* GraphQL */ `query GetUserAssessment($id: ID!) {
  getUserAssessment(id: $id) {
    id
    userId
    answers
    categoryScores
    overallScore
    lastUpdated
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAssessmentQueryVariables,
  APITypes.GetUserAssessmentQuery
>;
export const listUserAssessments = /* GraphQL */ `query ListUserAssessments(
  $filter: ModelUserAssessmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      answers
      categoryScores
      overallScore
      lastUpdated
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAssessmentsQueryVariables,
  APITypes.ListUserAssessmentsQuery
>;
export const getOrganizationalGoal = /* GraphQL */ `query GetOrganizationalGoal($id: ID!) {
  getOrganizationalGoal(id: $id) {
    id
    userId
    description
    isAIUseCase
    impact
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationalGoalQueryVariables,
  APITypes.GetOrganizationalGoalQuery
>;
export const listOrganizationalGoals = /* GraphQL */ `query ListOrganizationalGoals(
  $filter: ModelOrganizationalGoalFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationalGoals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      description
      isAIUseCase
      impact
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationalGoalsQueryVariables,
  APITypes.ListOrganizationalGoalsQuery
>;
export const getAIVision = /* GraphQL */ `query GetAIVision($id: ID!) {
  getAIVision(id: $id) {
    id
    userId
    visionStatement
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAIVisionQueryVariables,
  APITypes.GetAIVisionQuery
>;
export const listAIVisions = /* GraphQL */ `query ListAIVisions(
  $filter: ModelAIVisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAIVisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      visionStatement
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAIVisionsQueryVariables,
  APITypes.ListAIVisionsQuery
>;
export const getUseCasePrioritization = /* GraphQL */ `query GetUseCasePrioritization($id: ID!) {
  getUseCasePrioritization(id: $id) {
    id
    userId
    useCase
    impact
    feasibility
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUseCasePrioritizationQueryVariables,
  APITypes.GetUseCasePrioritizationQuery
>;
export const listUseCasePrioritizations = /* GraphQL */ `query ListUseCasePrioritizations(
  $filter: ModelUseCasePrioritizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUseCasePrioritizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      useCase
      impact
      feasibility
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUseCasePrioritizationsQueryVariables,
  APITypes.ListUseCasePrioritizationsQuery
>;
export const getActionPlan = /* GraphQL */ `query GetActionPlan($id: ID!) {
  getActionPlan(id: $id) {
    id
    userId
    steps {
      description
      dueDate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionPlanQueryVariables,
  APITypes.GetActionPlanQuery
>;
export const listActionPlans = /* GraphQL */ `query ListActionPlans(
  $filter: ModelActionPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionPlansQueryVariables,
  APITypes.ListActionPlansQuery
>;
export const getUserEthicalResponse = /* GraphQL */ `query GetUserEthicalResponse($id: ID!) {
  getUserEthicalResponse(id: $id) {
    id
    userId
    scenarioId
    selectedOption
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserEthicalResponseQueryVariables,
  APITypes.GetUserEthicalResponseQuery
>;
export const listUserEthicalResponses = /* GraphQL */ `query ListUserEthicalResponses(
  $filter: ModelUserEthicalResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEthicalResponses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      scenarioId
      selectedOption
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserEthicalResponsesQueryVariables,
  APITypes.ListUserEthicalResponsesQuery
>;
