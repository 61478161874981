import React, { useState, useEffect } from 'react';
import { generateClient } from '@aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';
import { listUserAssessments } from '../graphql/queries';
import { ListUserAssessmentsQuery } from '../API';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { AlertCircle } from 'lucide-react';
import '../components.css';

const client = generateClient();

const VisualCompass: React.FC = () => {
  const [chartData, setChartData] = useState<Array<{ category: string; value: number }>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAssessmentData();
  }, []);

  async function fetchAssessmentData() {
    try {
      setLoading(true);
      const { username } = await getCurrentUser();
      const result = await client.graphql<ListUserAssessmentsQuery>({
        query: listUserAssessments,
        variables: { filter: { userId: { eq: username } } }
      });

      console.log('Assessment data:', result);  // Log the entire result

      if ('data' in result && result.data?.listUserAssessments?.items.length > 0) {
        const assessment = result.data.listUserAssessments.items[0];
        console.log('First assessment item:', assessment);  // Log the first assessment item

        if (assessment.answers) {
          const answers = JSON.parse(assessment.answers);
          console.log('Parsed answers:', answers);  // Log the parsed answers

          const categoryScores = calculateCategoryScores(answers);
          console.log('Category scores:', categoryScores);  // Log the calculated category scores

          setChartData(Object.entries(categoryScores).map(([category, value]) => ({
            category,
            value: value as number
          })));
        } else {
          setError("Assessment data is incomplete. Please complete the AI Readiness Assessment.");
        }
      } else {
        setError("No assessment data found. Please complete the AI Readiness Assessment first.");
      }
    } catch (err: any) {
      console.error('Error fetching assessment data:', err);
      setError('Failed to fetch assessment data. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const calculateCategoryScores = (answers: { [key: string]: number }) => {
    const categories = {
      'Data Readiness': ['1.1', '1.2', '1.3'],
      'Technology Infrastructure': ['2.1', '2.2', '2.3'],
      'Workforce Capability': ['3.1', '3.2', '3.3'],
      'Organizational Culture': ['4.1', '4.2', '4.3'],
      'Governance and Ethics': ['5.1', '5.2', '5.3'],
      'Business Process Alignment': ['6.1', '6.2', '6.3'],
      'Leadership and Strategy': ['7.1', '7.2', '7.3'],
      'Financial Readiness': ['8.1', '8.2', '8.3']
    };

    const categoryScores: { [key: string]: number } = {};
    Object.entries(categories).forEach(([category, questionIds]) => {
      const categoryAnswers = questionIds.map(id => answers[id]).filter(Boolean);
      if (categoryAnswers.length > 0) {
        categoryScores[category] = categoryAnswers.reduce((sum, score) => sum + score, 0) / categoryAnswers.length;
      }
    });

    return categoryScores;
  };

  if (loading) return (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
      <div className="flex items-center">
        <AlertCircle className="mr-2" />
        <p className="font-bold">Error</p>
      </div>
      <p>{error}</p>
    </div>
  );

  if (chartData.length === 0) return (
    <div className="text-center p-6 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
      <p className="font-bold">No assessment data available.</p>
      <p>Please complete the AI Readiness Assessment first.</p>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">V.I.S.U.A.L. Compass</h2>
      <div className="bg-white bg-opacity-10 rounded-lg p-6 mb-6">
        <ResponsiveContainer width="100%" height={400}>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
            <PolarGrid stroke="#e5e7eb" />
            <PolarAngleAxis dataKey="category" stroke="#9ca3af" />
            <PolarRadiusAxis angle={30} domain={[0, 5]} stroke="#9ca3af" />
            <Radar
              name="AI Readiness"
              dataKey="value"
              stroke="#10b981"
              fill="#10b981"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
      <div className="bg-white bg-opacity-10 rounded-lg p-6">
        <h3 className="text-2xl font-semibold mb-4">AI Readiness Categories</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {chartData.map((item) => (
            <div key={item.category} className="flex justify-between items-center p-2 bg-white bg-opacity-5 rounded">
              <span>{item.category}:</span>
              <span className="font-bold">{item.value.toFixed(2)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VisualCompass;
