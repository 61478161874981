import React from 'react';
import { Link } from 'react-router-dom';
import { Compass, Target, Zap, Shield, BarChart2, BookOpen } from 'lucide-react';

const VisualCompassPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-blue-900 to-indigo-800 min-h-screen text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          V.I.S.U.A.L. Compass
        </h1>
        <Link 
          to="/" 
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-full transition-colors duration-300"
        >
          Back to Home
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img
            src="/images/A_futuristic_compass_800x600.png"
            alt="V.I.S.U.A.L. Compass"
            className="w-full md:w-1/2 rounded-lg shadow-2xl mb-8 md:mb-0 md:mr-8"
          />
          <div>
            <h2 className="text-3xl font-bold mb-4">Navigate Your AI Strategy with Precision</h2>
            <p className="text-lg mb-6">
              The V.I.S.U.A.L. Compass is your cutting-edge navigator in the complex world of AI strategy. 
              It offers a comprehensive framework for assessing, planning, and implementing AI initiatives 
              that align perfectly with your organization's goals and values.
            </p>
            <div className="bg-white bg-opacity-10 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">V.I.S.U.A.L. stands for:</h3>
              <ul className="space-y-2">
                <li><strong>V</strong>ision: Setting the AI Vision</li>
                <li><strong>I</strong>mpact: Assess the Impact of AI</li>
                <li><strong>S</strong>cale: Scale your workload from pilot to production</li>
                <li><strong>U</strong>se Cases: Drive the business with the Use cases</li>
                <li><strong>A</strong>rtificial Intelligence: AI, the technology behind transformation</li>
                <li><strong>L</strong>eadership: The talent and leadership you neeed for AI transformation</li>
              </ul>
            </div>
          </div>
        </div>

        <h3 className="text-2xl font-bold mb-6">Key Features</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { icon: Compass, title: "360-Degree View", description: "Get a comprehensive overview of your entire AI ecosystem" },
            { icon: Target, title: "Strategy Alignment", description: "Real-time indicators to keep your AI initiatives on target" },
            { icon: BarChart2, title: "Customizable Metrics", description: "Tailor KPIs to your specific business objectives" },
            { icon: Zap, title: "Quick Insights", description: "Rapid analysis for faster decision-making" },
            { icon: Shield, title: "Ethical AI Assurance", description: "Ensure your AI adheres to ethical standards and regulations" },
            { icon: BookOpen, title: "AI Literacy Boost", description: "Enhance your team's understanding of AI concepts and applications" }
          ].map((feature, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center">
              <feature.icon size={48} className="mb-4 text-blue-300" />
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-blue-800 bg-opacity-50 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Why Choose V.I.S.U.A.L. Compass?</h3>
          <p className="text-lg mb-6">
            In the rapidly evolving landscape of AI, having a reliable guide is crucial. 
            V.I.S.U.A.L. Compass doesn't just point you in the right direction—it provides 
            a comprehensive map of the terrain ahead, helping you navigate challenges, 
            seize opportunities, and stay ahead of the curve.
          </p>
          <Link 
            to="/dashboard" 
            className="inline-block px-6 py-3 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full font-semibold hover:from-purple-600 hover:to-blue-600 transition-colors duration-300"
          >
            Start Your AI Journey Now
          </Link>
        </div>
      </main>
    </div>
  );
};

export default VisualCompassPage;
