import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Compass, FileText, Shield, Grid, BarChart2, Target, Check, Users, ArrowRight } from 'lucide-react';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);

  const handleAuthAction = (action: 'signIn' | 'signUp') => {
    navigate('/dashboard', { state: { action } });
  };

const renderAuthButtons = () => {
    if (route === 'authenticated') {
      return (
        <button
          onClick={() => navigate('/dashboard')}
          className="px-6 py-2 bg-gradient-to-r from-green-500 to-blue-500 rounded-full font-semibold hover:from-green-600 hover:to-blue-600 transition-colors duration-300"
        >
          Go to Dashboard
        </button>
      );
    } else {
      return (
        <>
          <button
            onClick={() => handleAuthAction('signIn')}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full font-semibold hover:from-blue-600 hover:to-purple-700 transition-colors duration-300 mr-4"
          >
            Sign In
          </button>
          <button
            onClick={() => handleAuthAction('signUp')}
            className="px-6 py-2 bg-gradient-to-r from-green-500 to-blue-500 rounded-full font-semibold hover:from-green-600 hover:to-blue-600 transition-colors duration-300"
          >
            Sign Up
          </button>
        </>
      );
    }
  };



  const features = [
    {
      title: 'V.I.S.U.A.L. Compass',
      description: 'Navigate your AI strategy with precision',
      imageSrc: '/images/A_futuristic_compass_800x600.png',
      link: '/visual-compass',
      icon: Compass
    },
    {
      title: 'Strategy Blueprint',
      description: 'Design your AI roadmap for success',
      imageSrc: '/images/An_intricate_3D_hologram_800x600.png',
      link: '/strategy-blueprint',
      icon: FileText
    },
    {
      title: 'EthicalAI Simulator',
      description: 'Ensure ethical AI practices',
      imageSrc: '/images/A_glowing_translucent_cube_800x600.png',
      link: '/ethical-ai-simulator',
      icon: Shield
    },
    {
      title: 'Governance Matrix',
      description: 'Implement robust AI governance',
      imageSrc: '/images/A_digital_dashboard_800x600.png',
      link: '/governance-matrix',
      icon: Grid
    },
    {
      title: 'AI Readiness Assessment',
      description: 'Evaluate and enhance your AI capabilities',
      imageSrc: '/images/A_sleek_modern_device_800x600.png',
      link: '/ai-readiness-assessment',
      icon: BarChart2
    },
    {
      title: 'Use Case Identification',
      description: 'Identify and prioritize AI use cases',
      imageSrc: '/images/A_network_of_connected_nodes_800x600.png',
      link: '/use-case-identification',
      icon: Target
    }
  ];

  const benefits = [
    { title: 'Accelerate AI Adoption', description: 'Reduce time-to-value for AI initiatives by up to 40%' },
    { title: 'Mitigate Risks', description: 'Identify and address potential ethical and governance issues early' },
    { title: 'Optimize Resource Allocation', description: 'Ensure your AI investments deliver maximum ROI' },
    { title: 'Enhance Decision Making', description: 'Make data-driven decisions about your AI strategy' }
  ];

  const steps = [
    { title: 'Assess Readiness', description: 'Evaluate your organization\'s AI capabilities and maturity' },
    { title: 'Identify Opportunities', description: 'Discover high-impact AI use cases tailored to your business' },
    { title: 'Develop Strategy', description: 'Create a comprehensive AI roadmap aligned with your goals' },
    { title: 'Implement & Govern', description: 'Execute your strategy with built-in ethical considerations' }
  ];

  const testimonials = [
    { name: 'Jane Doe', title: 'CTO, Tech Innovators Inc.', quote: 'AI Strategy Suite helped us identify our most promising AI opportunities and accelerate our adoption by months.' },
    { name: 'John Smith', title: 'Head of AI, Global Finance Corp', quote: 'The Governance Matrix tool has been instrumental in ensuring our AI initiatives remain ethical and compliant.' }
  ];

  const faqs = [
    { question: 'How does the free trial work?', answer: 'Our 14-day free trial gives you full access to all features of the AI Strategy Suite. No credit card required.' },
    { question: 'Can I upgrade or downgrade my plan?', answer: 'Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.' },
    { question: 'Is my data secure?', answer: 'Absolutely. We use industry-standard encryption and security practices to keep your data safe and confidential.' },
    { question: 'Do you offer custom enterprise solutions?', answer: 'Yes, we offer tailored enterprise solutions. Contact our sales team for more information.' }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
      <header className="p-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          AI Strategy Suite
        </h1>
        <nav>
          <Link to="/articles" className="text-white hover:text-blue-300 mr-4">Articles</Link>
          <Link to="/contact" className="text-white hover:text-blue-300 mr-4">Contact Us</Link>
          <a href="https://chat.aistrat.app" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-300 mr-4">Chat</a>

          {renderAuthButtons()}
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-4">Master Your AI Strategy</h2>
          <p className="text-xl mb-8">Empower your organization with our cutting-edge AI strategy tools.</p>
          <button
            onClick={() => handleAuthAction('signUp')}
            className="inline-block px-8 py-3 bg-gradient-to-r from-green-500 to-blue-500 rounded-full font-semibold text-lg hover:from-green-600 hover:to-blue-600 transition-colors duration-300"
          >
            Start Free Trial
          </button>
        </div>

        {/* Key Benefits Section */}
        <section className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-center">Why Choose AI Strategy Suite?</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex items-start">
                <Check className="flex-shrink-0 h-6 w-6 text-green-400 mr-4" />
                <div>
                  <h4 className="text-xl font-semibold mb-2">{benefit.title}</h4>
                  <p className="text-gray-300">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

{/* Features Grid */}
<section className="mb-16">
  <h3 className="text-3xl font-bold mb-8 text-center">Our Comprehensive Toolkit</h3>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    {features.map((feature, index) => (
      <Link key={index} to={feature.link} className="bg-white bg-opacity-10 rounded-lg overflow-hidden transition-all duration-300 transform hover:scale-105 hover:bg-opacity-20">
        <img src={feature.imageSrc} alt={feature.title} className="w-full h-48 object-cover" />
        <div className="p-6">
          <div className="flex items-center mb-4">
            <feature.icon size={24} className="mr-2 text-blue-300" />
            <h3 className="text-xl font-semibold">{feature.title}</h3>
          </div>
          <p className="text-gray-300">{feature.description}</p>
        </div>
      </Link>
    ))}
  </div>
</section>
        {/* How It Works Section */}
        <section className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-center">How It Works</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center mx-auto mb-4">
                  <span className="text-2xl font-bold">{index + 1}</span>
                </div>
                <h4 className="text-xl font-semibold mb-2">{step.title}</h4>
                <p className="text-gray-300">{step.description}</p>
                {index < steps.length - 1 && (
                  <ArrowRight className="w-8 h-8 mx-auto mt-4 text-blue-400 hidden lg:block" />
                )}
              </div>
            ))}
          </div>
        </section>

        {/* Pricing Section Placeholder */}
        <section className="mb-16 bg-white bg-opacity-10 rounded-lg p-8">
          <h3 className="text-3xl font-bold mb-4 text-center">Flexible Pricing Options</h3>
          <p className="text-center text-xl mb-8">
            Choose the perfect plan for your organization's needs. From startups to enterprises, we have you covered.
          </p>
          <div className="text-center">
            <button
              onClick={() => handleAuthAction('signUp')}
              className="px-8 py-3 bg-gradient-to-r from-green-500 to-blue-500 rounded-full font-semibold text-lg hover:from-green-600 hover:to-blue-600 transition-colors duration-300"
            >
              View Pricing Plans
            </button>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-center">What Our Customers Say</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white bg-opacity-10 rounded-lg p-6">
                <p className="text-lg mb-4">"{testimonial.quote}"</p>
                <div className="flex items-center">
                  <Users className="w-12 h-12 text-blue-400 mr-4" />
                  <div>
                    <p className="font-semibold">{testimonial.name}</p>
                    <p className="text-sm text-gray-400">{testimonial.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* FAQ Section */}
        <section className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h3>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white bg-opacity-10 rounded-lg p-6">
                <h4 className="text-xl font-semibold mb-2">{faq.question}</h4>
                <p className="text-gray-300">{faq.answer}</p>
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className="text-center mb-16">
          <h3 className="text-3xl font-bold mb-4">Ready to Transform Your AI Strategy?</h3>
          <p className="text-xl mb-8">Join thousands of organizations already benefiting from AI Strategy Suite.</p>
          <button
            onClick={() => handleAuthAction('signUp')}
            className="inline-block px-8 py-3 bg-gradient-to-r from-green-500 to-blue-500 rounded-full font-semibold text-lg hover:from-green-600 hover:to-blue-600 transition-colors duration-300"
          >
            Start Your Free Trial
          </button>
        </section>
      </main>

      <footer className="mt-16 py-8 text-center text-gray-400">
        <p>&copy; 2024 AI Strategy Suite. All rights reserved.</p>
        <div className="mt-4">
          <Link to="/privacy-policy" className="text-blue-300 hover:text-blue-100 mx-2">Privacy Policy</Link>
          <Link to="/terms-of-use" className="text-blue-300 hover:text-blue-100 mx-2">Terms of Use</Link>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
