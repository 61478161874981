"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
exports.__esModule = true;
exports.deleteUserEthicalResponse = exports.updateUserEthicalResponse = exports.createUserEthicalResponse = exports.deleteActionPlan = exports.updateActionPlan = exports.createActionPlan = exports.deleteUseCasePrioritization = exports.updateUseCasePrioritization = exports.createUseCasePrioritization = exports.deleteAIVision = exports.updateAIVision = exports.createAIVision = exports.deleteOrganizationalGoal = exports.updateOrganizationalGoal = exports.createOrganizationalGoal = exports.deleteUserAssessment = exports.updateUserAssessment = exports.createUserAssessment = exports.deleteAssessmentResult = exports.updateAssessmentResult = exports.createAssessmentResult = exports.deleteAssessmentQuestion = exports.updateAssessmentQuestion = exports.createAssessmentQuestion = exports.deleteGovernanceItem = exports.updateGovernanceItem = exports.createGovernanceItem = exports.deleteEthicalScenario = exports.updateEthicalScenario = exports.createEthicalScenario = exports.deleteStrategy = exports.updateStrategy = exports.createStrategy = exports.deleteSkill = exports.updateSkill = exports.createSkill = void 0;
exports.createSkill = "mutation CreateSkill(\n  $input: CreateSkillInput!\n  $condition: ModelSkillConditionInput\n) {\n  createSkill(input: $input, condition: $condition) {\n    id\n    name\n    value\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateSkill = "mutation UpdateSkill(\n  $input: UpdateSkillInput!\n  $condition: ModelSkillConditionInput\n) {\n  updateSkill(input: $input, condition: $condition) {\n    id\n    name\n    value\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteSkill = "mutation DeleteSkill(\n  $input: DeleteSkillInput!\n  $condition: ModelSkillConditionInput\n) {\n  deleteSkill(input: $input, condition: $condition) {\n    id\n    name\n    value\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createStrategy = "mutation CreateStrategy(\n  $input: CreateStrategyInput!\n  $condition: ModelStrategyConditionInput\n) {\n  createStrategy(input: $input, condition: $condition) {\n    id\n    goal\n    actions\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateStrategy = "mutation UpdateStrategy(\n  $input: UpdateStrategyInput!\n  $condition: ModelStrategyConditionInput\n) {\n  updateStrategy(input: $input, condition: $condition) {\n    id\n    goal\n    actions\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteStrategy = "mutation DeleteStrategy(\n  $input: DeleteStrategyInput!\n  $condition: ModelStrategyConditionInput\n) {\n  deleteStrategy(input: $input, condition: $condition) {\n    id\n    goal\n    actions\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createEthicalScenario = "mutation CreateEthicalScenario(\n  $input: CreateEthicalScenarioInput!\n  $condition: ModelEthicalScenarioConditionInput\n) {\n  createEthicalScenario(input: $input, condition: $condition) {\n    id\n    description\n    options\n    correctOption\n    explanation\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateEthicalScenario = "mutation UpdateEthicalScenario(\n  $input: UpdateEthicalScenarioInput!\n  $condition: ModelEthicalScenarioConditionInput\n) {\n  updateEthicalScenario(input: $input, condition: $condition) {\n    id\n    description\n    options\n    correctOption\n    explanation\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteEthicalScenario = "mutation DeleteEthicalScenario(\n  $input: DeleteEthicalScenarioInput!\n  $condition: ModelEthicalScenarioConditionInput\n) {\n  deleteEthicalScenario(input: $input, condition: $condition) {\n    id\n    description\n    options\n    correctOption\n    explanation\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createGovernanceItem = "mutation CreateGovernanceItem(\n  $input: CreateGovernanceItemInput!\n  $condition: ModelGovernanceItemConditionInput\n) {\n  createGovernanceItem(input: $input, condition: $condition) {\n    id\n    area\n    policy\n    implementation\n    compliance\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateGovernanceItem = "mutation UpdateGovernanceItem(\n  $input: UpdateGovernanceItemInput!\n  $condition: ModelGovernanceItemConditionInput\n) {\n  updateGovernanceItem(input: $input, condition: $condition) {\n    id\n    area\n    policy\n    implementation\n    compliance\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteGovernanceItem = "mutation DeleteGovernanceItem(\n  $input: DeleteGovernanceItemInput!\n  $condition: ModelGovernanceItemConditionInput\n) {\n  deleteGovernanceItem(input: $input, condition: $condition) {\n    id\n    area\n    policy\n    implementation\n    compliance\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createAssessmentQuestion = "mutation CreateAssessmentQuestion(\n  $input: CreateAssessmentQuestionInput!\n  $condition: ModelAssessmentQuestionConditionInput\n) {\n  createAssessmentQuestion(input: $input, condition: $condition) {\n    id\n    question\n    category\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateAssessmentQuestion = "mutation UpdateAssessmentQuestion(\n  $input: UpdateAssessmentQuestionInput!\n  $condition: ModelAssessmentQuestionConditionInput\n) {\n  updateAssessmentQuestion(input: $input, condition: $condition) {\n    id\n    question\n    category\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteAssessmentQuestion = "mutation DeleteAssessmentQuestion(\n  $input: DeleteAssessmentQuestionInput!\n  $condition: ModelAssessmentQuestionConditionInput\n) {\n  deleteAssessmentQuestion(input: $input, condition: $condition) {\n    id\n    question\n    category\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createAssessmentResult = "mutation CreateAssessmentResult(\n  $input: CreateAssessmentResultInput!\n  $condition: ModelAssessmentResultConditionInput\n) {\n  createAssessmentResult(input: $input, condition: $condition) {\n    id\n    userId\n    category\n    score\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateAssessmentResult = "mutation UpdateAssessmentResult(\n  $input: UpdateAssessmentResultInput!\n  $condition: ModelAssessmentResultConditionInput\n) {\n  updateAssessmentResult(input: $input, condition: $condition) {\n    id\n    userId\n    category\n    score\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteAssessmentResult = "mutation DeleteAssessmentResult(\n  $input: DeleteAssessmentResultInput!\n  $condition: ModelAssessmentResultConditionInput\n) {\n  deleteAssessmentResult(input: $input, condition: $condition) {\n    id\n    userId\n    category\n    score\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.createUserAssessment = "mutation CreateUserAssessment(\n  $input: CreateUserAssessmentInput!\n  $condition: ModelUserAssessmentConditionInput\n) {\n  createUserAssessment(input: $input, condition: $condition) {\n    id\n    userId\n    answers\n    categoryScores\n    overallScore\n    lastUpdated\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.updateUserAssessment = "mutation UpdateUserAssessment(\n  $input: UpdateUserAssessmentInput!\n  $condition: ModelUserAssessmentConditionInput\n) {\n  updateUserAssessment(input: $input, condition: $condition) {\n    id\n    userId\n    answers\n    categoryScores\n    overallScore\n    lastUpdated\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.deleteUserAssessment = "mutation DeleteUserAssessment(\n  $input: DeleteUserAssessmentInput!\n  $condition: ModelUserAssessmentConditionInput\n) {\n  deleteUserAssessment(input: $input, condition: $condition) {\n    id\n    userId\n    answers\n    categoryScores\n    overallScore\n    lastUpdated\n    createdAt\n    updatedAt\n    __typename\n  }\n}\n";
exports.createOrganizationalGoal = "mutation CreateOrganizationalGoal(\n  $input: CreateOrganizationalGoalInput!\n  $condition: ModelOrganizationalGoalConditionInput\n) {\n  createOrganizationalGoal(input: $input, condition: $condition) {\n    id\n    userId\n    description\n    isAIUseCase\n    impact\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateOrganizationalGoal = "mutation UpdateOrganizationalGoal(\n  $input: UpdateOrganizationalGoalInput!\n  $condition: ModelOrganizationalGoalConditionInput\n) {\n  updateOrganizationalGoal(input: $input, condition: $condition) {\n    id\n    userId\n    description\n    isAIUseCase\n    impact\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteOrganizationalGoal = "mutation DeleteOrganizationalGoal(\n  $input: DeleteOrganizationalGoalInput!\n  $condition: ModelOrganizationalGoalConditionInput\n) {\n  deleteOrganizationalGoal(input: $input, condition: $condition) {\n    id\n    userId\n    description\n    isAIUseCase\n    impact\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.createAIVision = "mutation CreateAIVision(\n  $input: CreateAIVisionInput!\n  $condition: ModelAIVisionConditionInput\n) {\n  createAIVision(input: $input, condition: $condition) {\n    id\n    userId\n    visionStatement\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateAIVision = "mutation UpdateAIVision(\n  $input: UpdateAIVisionInput!\n  $condition: ModelAIVisionConditionInput\n) {\n  updateAIVision(input: $input, condition: $condition) {\n    id\n    userId\n    visionStatement\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteAIVision = "mutation DeleteAIVision(\n  $input: DeleteAIVisionInput!\n  $condition: ModelAIVisionConditionInput\n) {\n  deleteAIVision(input: $input, condition: $condition) {\n    id\n    userId\n    visionStatement\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.createUseCasePrioritization = "mutation CreateUseCasePrioritization(\n  $input: CreateUseCasePrioritizationInput!\n  $condition: ModelUseCasePrioritizationConditionInput\n) {\n  createUseCasePrioritization(input: $input, condition: $condition) {\n    id\n    userId\n    useCase\n    impact\n    feasibility\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateUseCasePrioritization = "mutation UpdateUseCasePrioritization(\n  $input: UpdateUseCasePrioritizationInput!\n  $condition: ModelUseCasePrioritizationConditionInput\n) {\n  updateUseCasePrioritization(input: $input, condition: $condition) {\n    id\n    userId\n    useCase\n    impact\n    feasibility\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteUseCasePrioritization = "mutation DeleteUseCasePrioritization(\n  $input: DeleteUseCasePrioritizationInput!\n  $condition: ModelUseCasePrioritizationConditionInput\n) {\n  deleteUseCasePrioritization(input: $input, condition: $condition) {\n    id\n    userId\n    useCase\n    impact\n    feasibility\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.createActionPlan = "mutation CreateActionPlan(\n  $input: CreateActionPlanInput!\n  $condition: ModelActionPlanConditionInput\n) {\n  createActionPlan(input: $input, condition: $condition) {\n    id\n    userId\n    steps {\n      description\n      dueDate\n      __typename\n    }\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateActionPlan = "mutation UpdateActionPlan(\n  $input: UpdateActionPlanInput!\n  $condition: ModelActionPlanConditionInput\n) {\n  updateActionPlan(input: $input, condition: $condition) {\n    id\n    userId\n    steps {\n      description\n      dueDate\n      __typename\n    }\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteActionPlan = "mutation DeleteActionPlan(\n  $input: DeleteActionPlanInput!\n  $condition: ModelActionPlanConditionInput\n) {\n  deleteActionPlan(input: $input, condition: $condition) {\n    id\n    userId\n    steps {\n      description\n      dueDate\n      __typename\n    }\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.createUserEthicalResponse = "mutation CreateUserEthicalResponse(\n  $input: CreateUserEthicalResponseInput!\n  $condition: ModelUserEthicalResponseConditionInput\n) {\n  createUserEthicalResponse(input: $input, condition: $condition) {\n    id\n    userId\n    scenarioId\n    selectedOption\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.updateUserEthicalResponse = "mutation UpdateUserEthicalResponse(\n  $input: UpdateUserEthicalResponseInput!\n  $condition: ModelUserEthicalResponseConditionInput\n) {\n  updateUserEthicalResponse(input: $input, condition: $condition) {\n    id\n    userId\n    scenarioId\n    selectedOption\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
exports.deleteUserEthicalResponse = "mutation DeleteUserEthicalResponse(\n  $input: DeleteUserEthicalResponseInput!\n  $condition: ModelUserEthicalResponseConditionInput\n) {\n  deleteUserEthicalResponse(input: $input, condition: $condition) {\n    id\n    userId\n    scenarioId\n    selectedOption\n    createdAt\n    updatedAt\n    owner\n    __typename\n  }\n}\n";
