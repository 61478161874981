import React, { useState, useEffect } from 'react';
import { generateClient } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { listGovernanceItems } from '../graphql/queries';
import { createGovernanceItem, updateGovernanceItem } from '../graphql/mutations';
import { AlertCircle, Plus, Save } from 'lucide-react';
import '../components.css';

const client = generateClient();

interface GovernanceItem {
  id: string;
  area: string;
  policy: string;
  implementation: string;
  compliance: number;
}

const predefinedItems: GovernanceItem[] = [
  {
    id: 'predefined-1',
    area: "Data Privacy",
    policy: "Data Encryption",
    implementation: "Implement end-to-end encryption for all sensitive data",
    compliance: 0
  },
  {
    id: 'predefined-2',
    area: "AI Ethics",
    policy: "Bias Mitigation",
    implementation: "Regular audits of AI models for potential biases",
    compliance: 0
  },
  // Add more predefined items here...
];

const GovernanceMatrix: React.FC = () => {
  const [items, setItems] = useState<GovernanceItem[]>([...predefinedItems]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newItem, setNewItem] = useState({ area: '', policy: '', implementation: '', compliance: 0 });

  useEffect(() => {
    fetchGovernanceItems();
  }, []);

  async function fetchGovernanceItems() {
    try {
      setLoading(true);
      const itemData: any = await client.graphql(graphqlOperation(listGovernanceItems));
      console.log("Fetched data:", itemData);

      let fetchedItems = itemData.data.listGovernanceItems.items;
      console.log("Fetched items:", fetchedItems);

      // Combine predefined items with fetched items
      const combinedItems = [...predefinedItems, ...fetchedItems.filter((item: GovernanceItem) =>
        !predefinedItems.some(predefined => predefined.id === item.id)
      )];

      setItems(combinedItems);
      console.log("Final items set:", combinedItems);
    } catch (err) {
      console.error('Error fetching governance items:', err);
      setError('Failed to fetch governance items. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  async function addGovernanceItem() {
    if (!newItem.area.trim() || !newItem.policy.trim() || !newItem.implementation.trim()) return;
    try {
      const result: any = await client.graphql(graphqlOperation(createGovernanceItem, { input: newItem }));
      const createdItem = result.data.createGovernanceItem;
      setItems([...items, createdItem]);
      setNewItem({ area: '', policy: '', implementation: '', compliance: 0 });
    } catch (err) {
      console.error('Error adding governance item:', err);
      setError('Failed to add governance item. Please try again.');
    }
  }

  async function updateCompliance(id: string, newCompliance: number) {
    try {
      if (id.startsWith('predefined-')) {
        // Update local state for predefined items
        setItems(items.map(item =>
          item.id === id ? { ...item, compliance: newCompliance } : item
        ));
      } else {
        // Update database for custom items
        await client.graphql(graphqlOperation(updateGovernanceItem, {
          input: { id, compliance: newCompliance }
        }));
        setItems(items.map(item =>
          item.id === id ? { ...item, compliance: newCompliance } : item
        ));
      }
    } catch (err) {
      console.error('Error updating compliance:', err);
      setError('Failed to update compliance. Please try again.');
    }
  }

  if (loading) return (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>
  );

  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Governance Matrix</h2>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <div className="flex items-center">
            <AlertCircle className="mr-2" />
            <p className="font-bold">Error</p>
          </div>
          <p>{error}</p>
          <button 
            className="mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            onClick={fetchGovernanceItems}
          >
            Retry
          </button>
        </div>
      )}

      <div className="bg-white bg-opacity-10 rounded-lg p-6 mb-6 overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="p-2">Area</th>
              <th className="p-2">Policy</th>
              <th className="p-2">Implementation</th>
              <th className="p-2">Compliance (%)</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id} className="border-t border-gray-200">
                <td className="p-2">{item.area}</td>
                <td className="p-2">{item.policy}</td>
                <td className="p-2">{item.implementation}</td>
                <td className="p-2">
                  <input
                    type="number"
                    className="w-20 p-1 bg-white bg-opacity-10 rounded"
                    value={item.compliance}
                    onChange={(e) => updateCompliance(item.id, parseInt(e.target.value))}
                    min="0"
                    max="100"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-white bg-opacity-10 rounded-lg p-6">
        <h3 className="text-2xl font-semibold mb-4">Add New Item</h3>
        <div className="space-y-4">
          <input
            className="w-full p-2 bg-white bg-opacity-10 rounded"
            type="text"
            value={newItem.area}
            onChange={(e) => setNewItem({...newItem, area: e.target.value})}
            placeholder="Area"
          />
          <input
            className="w-full p-2 bg-white bg-opacity-10 rounded"
            type="text"
            value={newItem.policy}
            onChange={(e) => setNewItem({...newItem, policy: e.target.value})}
            placeholder="Policy"
          />
          <input
            className="w-full p-2 bg-white bg-opacity-10 rounded"
            type="text"
            value={newItem.implementation}
            onChange={(e) => setNewItem({...newItem, implementation: e.target.value})}
            placeholder="Implementation"
          />
          <input
            className="w-full p-2 bg-white bg-opacity-10 rounded"
            type="number"
            value={newItem.compliance}
            onChange={(e) => setNewItem({...newItem, compliance: parseInt(e.target.value)})}
            placeholder="Compliance (%)"
          />
          <button 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            onClick={addGovernanceItem}
          >
            <Plus className="mr-2" size={20} />
            Add Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default GovernanceMatrix;
