// src/pages/ArticlePage.tsx
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ArticleContent from './ArticleContent';

const ArticlePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
      <header className="p-6 flex justify-between items-center">
        <Link to="/" className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          AI Strategy Suite
        </Link>
        <nav>
          <Link to="/articles" className="text-white hover:text-blue-300 mr-4">Back to Articles</Link>
        </nav>
      </header>
      <main className="container mx-auto px-4 py-12">
        <ArticleContent id={id || ''} />
      </main>
      <footer className="mt-16 py-8 text-center text-gray-400">
        <p>&copy; 2024 AI Strategy Suite. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ArticlePage;
