import React, { useState, useRef, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { Input } from '../components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { AlertCircle, Download, ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import config from '../config';
import { generateClient } from '@aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';


interface AIReadinessScore {
  overallScore: number;
  categoryScores: {
    [key: string]: number;
  };
}

interface StrategyInput {
  industry: string;
  businessGoals: string;
  budget: string;
  challenges: string;
  aiReadinessScore: AIReadinessScore;
}

interface AIStrategy {
  executiveSummary?: {
    keyFindings?: string[];
    topRecommendations?: string[];
    aiReadinessScore?: number;
  };
  strategicAIInitiatives?: Array<{
    name: string;
    description: string;
    expectedImpact: string;
    alignmentWithBusinessGoals: string;
  }>;
  implementationRoadmap?: {
    phase1?: string[];
    phase2?: string[];
    phase3?: string[];
  };
  resourceAllocation?: {
    [key: string]: number;
  };
  riskAndMitigation?: Array<{
    risk: string;
    mitigation: string;
  }>;
  nextSteps?: string[];
}

// Add a new interface for strategy metadata
interface StrategyMetadata {
  id: string;
  timestamp: string;
  strategy: AIStrategy;
}


interface AIStrategyGeneratorProps {
  aiReadinessScore: AIReadinessScore;
}

const AIStrategyGenerator: React.FC<AIStrategyGeneratorProps> = ({ aiReadinessScore }) => {
  const [formData, setFormData] = useState<StrategyInput>({
    industry: '',
    businessGoals: '',
    budget: '',
    challenges: '',
    aiReadinessScore: aiReadinessScore,
  });
  const [generatedStrategy, setGeneratedStrategy] = useState<AIStrategy | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tabsRef = useRef<HTMLDivElement>(null);
  const strategyContentRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkForArrows = () => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };
  const [strategies, setStrategies] = useState<StrategyMetadata[]>([]);
  const [selectedStrategy, setSelectedStrategy] = useState<AIStrategy | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const generatePDF = (strategy: AIStrategy) => {
  const doc = new jsPDF();
  let yOffset = 10;

      // Helper function to add a section title
    const addSectionTitle = (title: string) => {
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 255);  // Blue color for titles
      doc.text(title, 10, yOffset);
      yOffset += 10;
    };

    // Helper function to add text content
    const addContent = (content: string | string[]) => {
      doc.setFontSize(12);
      doc.setTextColor(0);
      if (Array.isArray(content)) {
        content.forEach(item => {
          doc.text('• ' + item, 15, yOffset);
          yOffset += 7;
        });
      } else {
        const lines = doc.splitTextToSize(content, 180);
        doc.text(lines, 15, yOffset);
        yOffset += 7 * lines.length;
      }
      yOffset += 5;
    };

    // Title
    doc.setFontSize(20);
    doc.setTextColor(0, 102, 204);
    doc.text('AI Strategy Report', 105, yOffset, { align: 'center' });
    yOffset += 20;

    // Executive Summary
    if (strategy.executiveSummary) {
      addSectionTitle('Executive Summary');
      if (strategy.executiveSummary.keyFindings) {
        doc.setFontSize(14);
        doc.text('Key Findings:', 15, yOffset);
        yOffset += 10;
        addContent(strategy.executiveSummary.keyFindings);
      }
      if (strategy.executiveSummary.topRecommendations) {
        doc.setFontSize(14);
        doc.text('Top Recommendations:', 15, yOffset);
        yOffset += 10;
        addContent(strategy.executiveSummary.topRecommendations);
      }
      if (strategy.executiveSummary.aiReadinessScore !== undefined) {
        doc.setFontSize(14);
        doc.text(`AI Readiness Score: ${strategy.executiveSummary.aiReadinessScore}`, 15, yOffset);
        yOffset += 10;
      }
    }

    // Strategic AI Initiatives
    if (strategy.strategicAIInitiatives) {
      addSectionTitle('Strategic AI Initiatives');
      strategy.strategicAIInitiatives.forEach((initiative, index) => {
        doc.setFontSize(14);
        doc.text(`Initiative ${index + 1}: ${initiative.name}`, 15, yOffset);
        yOffset += 7;
        addContent(`Description: ${initiative.description}`);
        addContent(`Expected Impact: ${initiative.expectedImpact}`);
        addContent(`Alignment with Business Goals: ${initiative.alignmentWithBusinessGoals}`);
        yOffset += 5;
      });
    }

    // Implementation Roadmap
    if (strategy.implementationRoadmap) {
      addSectionTitle('Implementation Roadmap');
      Object.entries(strategy.implementationRoadmap).forEach(([phase, steps]) => {
        doc.setFontSize(14);
        doc.text(phase.charAt(0).toUpperCase() + phase.slice(1) + ':', 15, yOffset);
        yOffset += 7;
        addContent(steps);
      });
    }

    // Resource Allocation
    if (strategy.resourceAllocation) {
      addSectionTitle('Resource Allocation');
      Object.entries(strategy.resourceAllocation).forEach(([initiative, allocation]) => {
        addContent(`${initiative}: $${allocation}`);
      });
    }

    // Risk and Mitigation
    if (strategy.riskAndMitigation) {
      addSectionTitle('Risk and Mitigation Strategies');
      strategy.riskAndMitigation.forEach((item, index) => {
        doc.setFontSize(14);
        doc.text(`Risk ${index + 1}:`, 15, yOffset);
        yOffset += 7;
        addContent(`${item.risk}`);
        doc.text('Mitigation:', 15, yOffset);
        yOffset += 7;
        addContent(`${item.mitigation}`);
        yOffset += 5;
      });
    }

    // Next Steps
    if (strategy.nextSteps) {
      addSectionTitle('Next Steps');
      addContent(strategy.nextSteps);
    }

    // Save the PDF
    doc.save('AI_Strategy_Report.pdf');
  };

    const handleExportPDF = () => {
    if (generatedStrategy) {
      generatePDF(generatedStrategy);
    } else {
      setError('No strategy available to export. Please generate or select a strategy first.');
    }
  };


  useEffect(() => {
    const fetchUserAndStrategies = async () => {
      try {
        const user = await getCurrentUser();
        setUserId(user.userId);
        fetchStrategies(user.userId);
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to fetch user information.');
      }
    };

    fetchUserAndStrategies();
  }, []);

  const fetchStrategies = async (userId: string) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/strategies?user_id=${userId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStrategies(data.strategies);
    } catch (err) {
      console.error('Error fetching strategies:', err);
      setError('Failed to fetch strategies. Please try again.');
    }
  };


  useEffect(() => {
    checkForArrows();
    window.addEventListener('resize', checkForArrows);
    return () => window.removeEventListener('resize', checkForArrows);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (tabsRef.current) {
      const scrollAmount = tabsRef.current.clientWidth / 2;
      tabsRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(checkForArrows, 100);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Modify handleSubmit to include userId
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedStrategy(null);

    try {
      const response = await fetch(`${config.apiUrl}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          aiReadinessScore,
          user_id: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setGeneratedStrategy(data.strategy);
      fetchStrategies(userId!); // Refresh the list of strategies
    } catch (err) {
      setError(`Failed to generate strategy: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
    }
  };

const exportToPDF = async () => {
  console.log("Starting PDF export...");
  if (!strategyContentRef.current) {
    console.error("Strategy content ref is null");
    return;
  }

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const margins = 10; // margins in mm

  const sections = [
    { title: "Executive Summary", value: "summary" },
    { title: "Strategic Initiatives", value: "initiatives" },
    { title: "Implementation Roadmap", value: "roadmap" },
    { title: "Resource Allocation", value: "resources" },
    { title: "Risks and Mitigation", value: "risks" },
    { title: "Next Steps", value: "nextSteps" }
  ];

  const addWatermark = (pdf: jsPDF) => {
    const watermarkText = "AI Guru";
    pdf.saveGraphicsState();
    pdf.setGState((pdf as any).GState({ opacity: 0.3 }));
    pdf.setTextColor(200, 200, 200); // Light gray
    pdf.setFontSize(60);
    pdf.text(watermarkText, pdfWidth / 2, pdfHeight / 2, {
      align: 'center',
      angle: 45
    });
    pdf.restoreGraphicsState();
  };

  for (const section of sections) {
    console.log(`Processing section: ${section.title}`);
    const sectionElement = document.querySelector(`[data-value="${section.value}"]`) as HTMLElement;
    if (sectionElement) {
      // Add section title
      pdf.setFontSize(16);
      pdf.text(section.title, margins, margins + 10);

      try {
        // Capture and add section content
        const canvas = await html2canvas(sectionElement);
        console.log(`Canvas created for ${section.title}`);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdfWidth - (2 * margins);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        
        let heightLeft = imgHeight;
        let position = margins + 20; // Start below the title

        pdf.addImage(imgData, 'PNG', margins, position, imgWidth, imgHeight);
        heightLeft -= (pdfHeight - position);
        
        while (heightLeft > 0) {
          position = margins;
          pdf.addPage();
          addWatermark(pdf);
          pdf.addImage(imgData, 'PNG', margins, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= (pdfHeight - margins);
        }

        // Add watermark to the first page of each section
        addWatermark(pdf);

        // Add a page break after each section (except the last one)
        if (section !== sections[sections.length - 1]) {
          pdf.addPage();
        }
      } catch (error) {
        console.error(`Error processing section ${section.title}:`, error);
      }
    } else {
      console.warn(`Section element not found for ${section.title}`);
    }
  }

  console.log("PDF generation complete. Attempting to save...");
  try {
    pdf.save('comprehensive_ai_strategy.pdf');
    console.log("PDF saved successfully");
  } catch (error) {
    console.error("Error saving PDF:", error);
  }
};


  const renderInputForm = () => (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        name="industry"
        value={formData.industry}
        onChange={handleInputChange}
        placeholder="Industry (e.g., Finance, Healthcare)"
        className="text-black bg-white"
      />
      <Textarea
        name="businessGoals"
        value={formData.businessGoals}
        onChange={handleInputChange}
        placeholder="Business Goals (e.g., Improve customer service, Increase sales)"
        className="text-black bg-white"
      />
      <Input
        name="budget"
        value={formData.budget}
        onChange={handleInputChange}
        placeholder="Budget (e.g., $100,000)"
        className="text-black bg-white"
      />
      <Textarea
        name="challenges"
        value={formData.challenges}
        onChange={handleInputChange}
        placeholder="Main Operational Challenges (e.g., Inventory management, Fraud detection)"
        className="text-black bg-white"
      />
      <Button type="submit" disabled={loading}>
        Generate Strategy
      </Button>
    </form>
  );

  const renderStrategyDisplay = () => {
    if (!generatedStrategy) return null;

    return (
      <div className="mt-8" id="strategy-content" ref={strategyContentRef}>
        <h2 className="text-2xl font-bold mb-4">Generated AI Strategy</h2>
        <Tabs defaultValue="summary" className="w-full" onValueChange={checkForArrows}>
          <div className="relative flex items-center mb-4">
            {showLeftArrow && (
              <Button
                className="absolute left-0 z-10 p-1 bg-opacity-50 rounded-full"
                onClick={() => scroll('left')}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
            )}
            <div className="overflow-hidden flex-grow" ref={tabsRef}>
              <TabsList className="inline-flex w-max space-x-2 p-2 transition-transform duration-300 ease-in-out">
                <TabsTrigger value="summary">Executive Summary</TabsTrigger>
                <TabsTrigger value="initiatives">Strategic Initiatives</TabsTrigger>
                <TabsTrigger value="roadmap">Implementation Roadmap</TabsTrigger>
                <TabsTrigger value="resources">Resource Allocation</TabsTrigger>
                <TabsTrigger value="risks">Risks and Mitigation</TabsTrigger>
                <TabsTrigger value="nextSteps">Next Steps</TabsTrigger>
              </TabsList>
            </div>
            {showRightArrow && (
              <Button
                className="absolute right-0 z-10 p-1 bg-opacity-50 rounded-full"
                onClick={() => scroll('right')}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            )}
          </div>
          <TabsContent value="summary" data-value="summary">
            <Card>
              <CardHeader>
                <CardTitle>Executive Summary</CardTitle>
              </CardHeader>
              <CardContent>
                <h4 className="font-semibold">Key Findings:</h4>
                <ul className="list-disc pl-5 mb-4">
                  {generatedStrategy.executiveSummary?.keyFindings?.map((finding, index) => (
                    <li key={index}>{finding}</li>
                  ))}
                </ul>
                <h4 className="font-semibold">Top Recommendations:</h4>
                <ul className="list-disc pl-5 mb-4">
                  {generatedStrategy.executiveSummary?.topRecommendations?.map((recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  ))}
                </ul>
                <p>AI Readiness Score: {generatedStrategy.executiveSummary?.aiReadinessScore}</p>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="initiatives" data-value="initiatives">
            <Card>
              <CardHeader>
                <CardTitle>Strategic AI Initiatives</CardTitle>
              </CardHeader>
              <CardContent>
                {generatedStrategy.strategicAIInitiatives?.map((initiative, index) => (
                  <div key={index} className="mb-4">
                    <h4 className="font-semibold">{initiative.name}</h4>
                    <p><strong>Description:</strong> {initiative.description}</p>
                    <p><strong>Expected Impact:</strong> {initiative.expectedImpact}</p>
                    <p><strong>Alignment with Business Goals:</strong> {initiative.alignmentWithBusinessGoals}</p>
                  </div>
                ))}
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="roadmap" data-value="roadmap">
            <Card>
              <CardHeader>
                <CardTitle>Implementation Roadmap</CardTitle>
              </CardHeader>
              <CardContent>
                {generatedStrategy.implementationRoadmap && Object.entries(generatedStrategy.implementationRoadmap).map(([phase, steps]) => (
                  <div key={phase} className="mb-4">
                    <h4 className="font-semibold">{phase.charAt(0).toUpperCase() + phase.slice(1)}</h4>
                    <ul className="list-disc pl-5">
                      {steps?.map((step, stepIndex) => (
                        <li key={stepIndex}>{step}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="resources" data-value="resources">
            <Card>
              <CardHeader>
                <CardTitle>Resource Allocation</CardTitle>
              </CardHeader>
              <CardContent>
                <ul>
                  {generatedStrategy.resourceAllocation && Object.entries(generatedStrategy.resourceAllocation).map(([initiative, allocation], index) => (
                    <li key={index}>
                      <strong>{initiative}:</strong> ${allocation.toLocaleString()}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="risks" data-value="risks">
            <Card>
              <CardHeader>
                <CardTitle>Risks and Mitigation</CardTitle>
              </CardHeader>
              <CardContent>
                {generatedStrategy.riskAndMitigation?.map((item, index) => (
                  <div key={index} className="mb-4">
                    <p><strong>Risk:</strong> {item.risk}</p>
                    <p><strong>Mitigation:</strong> {item.mitigation}</p>
                  </div>
                ))}
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="nextSteps" data-value="nextSteps">
            <Card>
              <CardHeader>
                <CardTitle>Next Steps</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc pl-5">
                  {generatedStrategy.nextSteps?.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
	      <Button onClick={handleExportPDF} className="mt-4">
          <Download className="mr-2 h-4 w-4" /> Export Comprehensive PDF Report
        </Button>
      </div>
    );
  };

  const handleViewStrategy = (strategy: AIStrategy) => {
    setSelectedStrategy(strategy);
    setGeneratedStrategy(strategy);  // This will update the main strategy display
  };

  const renderStrategyList = () => (
    <Card className="mt-8">
      <CardHeader>
        <h2 className="text-xl font-semibold">Your Strategies</h2>
      </CardHeader>
      <CardContent>
        {strategies.length > 0 ? (
          <ul>
            {strategies.map((strategyMeta) => (
              <li key={strategyMeta.id} className="mb-4">
                <h3 className="font-semibold">{new Date(strategyMeta.timestamp).toLocaleString()}</h3>
                <Button onClick={() => handleViewStrategy(strategyMeta.strategy)} className="mt-2">
                  View Strategy
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No strategies found. Generate your first strategy above!</p>
        )}
        <Button onClick={() => fetchStrategies(userId!)} className="mt-4">
          <RefreshCw className="mr-2 h-4 w-4" /> Refresh Strategies
        </Button>
      </CardContent>
    </Card>
  );


  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">AI Strategy Generator</h1>

      {renderInputForm()}

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div className="flex items-center">
            <AlertCircle className="mr-2" />
            <p className="font-bold">Error</p>
          </div>
          <p>{error}</p>
        </div>
      )}

      {loading && (
        <div className="text-center py-4">
          <p>Generating AI Strategy...</p>
        </div>
      )}

      {renderStrategyDisplay()}
      {renderStrategyList()}
    </div>
  );
};

export default AIStrategyGenerator;

