import React, { useState, useEffect } from 'react';
import { generateClient } from '@aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';
import { createUserAssessment, updateUserAssessment } from '../graphql/mutations';
import { listUserAssessments } from '../graphql/queries';
import { CreateUserAssessmentMutation, UpdateUserAssessmentMutation, ListUserAssessmentsQuery } from '../API';
import { CheckCircle, AlertCircle, Edit3, Save, X } from 'lucide-react';
import '../components.css';

const client = generateClient();

interface Question {
  id: string;
  text: string;
  category: string;
}

interface Category {
  name: string;
  weight: number;
  questions: Question[];
}

const categories: Category[] = [
  {
    name: "Data Readiness",
    weight: 0.15,
    questions: [
      { id: "1.1", text: "How would you rate the availability of relevant data in your organization?", category: "Data Readiness" },
      { id: "1.2", text: "How would you rate the quality (accuracy, completeness, consistency) of your data?", category: "Data Readiness" },
      { id: "1.3", text: "How easily can data be accessed and used across your organization?", category: "Data Readiness" }
    ]
  },
  {
    name: "Technology Infrastructure",
    weight: 0.15,
    questions: [
      { id: "2.1", text: "How adequate are your computing resources (e.g., GPUs, cloud resources) for AI initiatives?", category: "Technology Infrastructure" },
      { id: "2.2", text: "How would you rate your network infrastructure's capacity and speed for AI workloads?", category: "Technology Infrastructure" },
      { id: "2.3", text: "To what extent does your organization have AI/ML development and deployment platforms in place?", category: "Technology Infrastructure" }
    ]
  },
  {
    name: "Workforce Capability",
    weight: 0.15,
    questions: [
      { id: "3.1", text: "How would you rate the presence of AI/ML technical skills within your organization?", category: "Workforce Capability" },
      { id: "3.2", text: "How would you assess the general AI literacy across your organization?", category: "Workforce Capability" },
      { id: "3.3", text: "How ready is your workforce to adapt to AI-driven changes in their roles?", category: "Workforce Capability" }
    ]
  },
  {
    name: "Organizational Culture",
    weight: 0.1,
    questions: [
      { id: "4.1", text: "How open is your organization to adopting new technologies and ways of working?", category: "Organizational Culture" },
      { id: "4.2", text: "How would you rate your organization's tolerance for the risks associated with AI projects?", category: "Organizational Culture" },
      { id: "4.3", text: "To what extent does your organization foster cross-functional collaboration?", category: "Organizational Culture" }
    ]
  },
  {
    name: "Governance and Ethics",
    weight: 0.1,
    questions: [
      { id: "5.1", text: "How developed are your organization's AI-specific policies and guidelines?", category: "Governance and Ethics" },
      { id: "5.2", text: "How prepared is your organization to handle AI ethics issues?", category: "Governance and Ethics" },
      { id: "5.3", text: "How would you rate your organization's ability to ensure AI compliance with relevant regulations?", category: "Governance and Ethics" }
    ]
  },
  {
    name: "Business Process Alignment",
    weight: 0.1,
    questions: [
      { id: "6.1", text: "How well-documented are your current business processes?", category: "Business Process Alignment" },
      { id: "6.2", text: "To what extent have you identified processes suitable for AI-driven automation?", category: "Business Process Alignment" },
      { id: "6.3", text: "How easily can AI solutions be integrated into your existing processes?", category: "Business Process Alignment" }
    ]
  },
  {
    name: "Leadership and Strategy",
    weight: 0.15,
    questions: [
      { id: "7.1", text: "How clear is your organization's vision for AI?", category: "Leadership and Strategy" },
      { id: "7.2", text: "How strong is the support for AI initiatives from top management?", category: "Leadership and Strategy" },
      { id: "7.3", text: "How well do potential AI initiatives align with your overall business strategy?", category: "Leadership and Strategy" }
    ]
  },
  {
    name: "Financial Readiness",
    weight: 0.1,
    questions: [
      { id: "8.1", text: "How adequate is your budget allocation for AI initiatives?", category: "Financial Readiness" },
      { id: "8.2", text: "How realistic are your organization's expectations for AI ROI?", category: "Financial Readiness" },
      { id: "8.3", text: "How diverse are your potential funding sources for AI projects?", category: "Financial Readiness" }
    ]
  }
];

interface AIReadinessAssessmentProps {
  onAssessmentUpdate?: (data: { [key: string]: number }) => void;
}

const AIReadinessAssessment: React.FC<AIReadinessAssessmentProps> = ({ onAssessmentUpdate }) => {
  const [answers, setAnswers] = useState<{ [key: string]: number }>({});
  const [assessmentId, setAssessmentId] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([]);

  useEffect(() => {
    loadExistingAssessment();
  }, []);

  async function loadExistingAssessment() {
    try {
      setLoading(true);
      const { username } = await getCurrentUser();
      const result = await client.graphql<ListUserAssessmentsQuery>({
        query: listUserAssessments,
        variables: { filter: { userId: { eq: username } } }
      });
      if ('data' in result && result.data?.listUserAssessments?.items.length > 0) {
        const assessment = result.data.listUserAssessments.items[0];
        setAssessmentId(assessment.id);
        const parsedAnswers = JSON.parse(assessment.answers);
        setAnswers(parsedAnswers);
        handleAssessmentUpdate(parsedAnswers);
      } else {
        console.log("No existing assessment found");
      }
    } catch (err: any) {
      console.error('Error loading existing assessment:', err);
      setError('Failed to load existing assessment. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleAssessmentUpdate = (answers: { [key: string]: number }) => {
    if (onAssessmentUpdate) {
      const categoryScores = calculateCategoryScores(answers);
      onAssessmentUpdate(categoryScores);
    }
  };

  async function saveAssessment() {
    const unanswered = categories.flatMap(category =>
      category.questions.filter(question => !answers[question.id])
    ).map(question => question.id);

    setUnansweredQuestions(unanswered);

    if (unanswered.length > 0) {
      setError(`Please answer all questions before saving. ${unanswered.length} questions are unanswered.`);
      return;
    }

    try {
      const { username } = await getCurrentUser();
      const score = calculateScores();
      const lastUpdated = new Date().toISOString();

      if (score === null || isNaN(score)) {
        throw new Error("Invalid score calculated");
      }

      const input = {
        id: assessmentId,
        userId: username,
        answers: JSON.stringify(answers),
        score: score,
        totalScore: score,
        lastUpdated: lastUpdated
      };

      if (assessmentId) {
        await client.graphql<UpdateUserAssessmentMutation>({
          query: updateUserAssessment,
          variables: { input }
        });
      } else {
        const result = await client.graphql<CreateUserAssessmentMutation>({
          query: createUserAssessment,
          variables: { input: { ...input, id: undefined } }
        });
        if ('data' in result && result.data?.createUserAssessment?.id) {
          setAssessmentId(result.data.createUserAssessment.id);
        }
      }
      setIsEditMode(false);
      handleAssessmentUpdate(answers);
      setError(null);
    } catch (err: any) {
      console.error('Error saving assessment:', err);
      setError(`Failed to save assessment: ${err.message}`);
    }
  }

  const handleAnswer = (questionId: string, rating: number) => {
    setAnswers(prev => ({ ...prev, [questionId]: rating }));
    setUnansweredQuestions(prev => prev.filter(id => id !== questionId));
    setError(null);
  };

  const calculateCategoryScores = (answers: { [key: string]: number }) => {
    const categoryScores: { [key: string]: number } = {};
    categories.forEach(category => {
      const categoryQuestions = category.questions;
      const answeredQuestions = categoryQuestions.filter(question => answers[question.id] !== undefined);
      if (answeredQuestions.length > 0) {
        const categoryScore = answeredQuestions.reduce((sum, question) =>
          sum + (answers[question.id] || 0), 0) / answeredQuestions.length;
        categoryScores[category.name] = categoryScore;
      }
    });
    return categoryScores;
  };

  const calculateScores = () => {
    const categoryScores = calculateCategoryScores(answers);
    const overallScore = Object.values(categoryScores).reduce((sum, score) => sum + score, 0) / Object.keys(categoryScores).length;
    return isNaN(overallScore) ? 0 : overallScore;
  };

  if (loading) return <div className="text-center py-8">Loading assessment...</div>;

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">AI Readiness Assessment</h2>

      <div className="bg-white bg-opacity-10 p-6 rounded-lg mb-6">
        <p>An AI readiness assessment is a systematic evaluation process that determines how
prepared your organization is to adopt and implement Artificial Intelligence (AI)
technologies.</p> <p>The assessment looks at the current state of AI-related resources and processes,
identifies gaps and challenges, and measures the organization's capacity for successful
AI integration.</p>
      </div>

      <div className="bg-white bg-opacity-10 p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-4">Assessment Legend</h3>
        <p>Please rate your organization on a scale of 1 to 5 for each question:</p>
        <ul className="list-disc list-inside mt-2">
          <li>1 = Not Ready at All</li>
          <li>2 = Somewhat Ready</li>
          <li>3 = Moderately Ready</li>
          <li>4 = Very Ready</li>
          <li>5 = Fully Ready</li>
        </ul>
      </div>

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      {isEditMode ? (
        <div>
          {categories.map((category) => (
            <div key={category.name} className="bg-white bg-opacity-10 rounded-lg p-6 mb-6">
              <h3 className="text-2xl font-semibold mb-4">{category.name}</h3>
              {category.questions.map((question) => (
                <div key={question.id} className="mb-6">
                  <p className="mb-2">{question.text}</p>
                  <div className="flex space-x-2">
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <button
                        key={rating}
                        className={`flex-1 py-2 px-4 rounded ${
                          answers[question.id] === rating
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        } ${unansweredQuestions.includes(question.id) ? 'border-2 border-red-500' : ''}`}
                        onClick={() => handleAnswer(question.id, rating)}
                      >
                        {rating}
                      </button>
                    ))}
                  </div>
                  {unansweredQuestions.includes(question.id) && (
                    <p className="text-red-500 mt-1">Please answer this question</p>
                  )}
                </div>
              ))}
            </div>
          ))}
          <div className="flex justify-end space-x-4">
            <button
              className="px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors duration-300 flex items-center"
              onClick={saveAssessment}
              disabled={unansweredQuestions.length > 0}
            >
              <Save className="mr-2" size={20} />
              Save Assessment
            </button>
            <button
              className="px-6 py-2 bg-gray-600 text-white rounded-full hover:bg-gray-700 transition-colors duration-300 flex items-center"
              onClick={() => {
                setIsEditMode(false);
                setUnansweredQuestions([]);
                setError(null);
              }}
            >
              <X className="mr-2" size={20} />
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center bg-white bg-opacity-10 rounded-lg p-6">
          {assessmentId ? (
            <>
              <p className="text-3xl font-bold mb-4">Your AI Readiness Score: {calculateScores().toFixed(2)}</p>
              <button
                className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-300 flex items-center mx-auto"
                onClick={() => setIsEditMode(true)}
              >
                <Edit3 className="mr-2" size={20} />
                Edit Assessment
              </button>
            </>
          ) : (
            <button
              className="px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors duration-300 flex items-center mx-auto"
              onClick={() => setIsEditMode(true)}
            >
              <CheckCircle className="mr-2" size={20} />
              Start Assessment
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AIReadinessAssessment;
