import React from 'react';
import { Link } from 'react-router-dom';

interface Article {
  id: string;
  title: string;
  summary: string;
  date: string;
  author: string;
}

const articles: Article[] = [
  {
    id: '1',
    title: 'The Multimodal Revolution: How Generative AI is Transforming Industries',
    summary: 'Explore how multimodal Generative AI is reshaping various sectors, from finance to healthcare, retail, and environmental science.',
    date: '2024-03-20',
    author: 'AI Strategy Expert'
  },
  {
    id: '2',
    title: 'Ethical Considerations in AI Implementation',
    summary: 'Understand the key ethical challenges when adopting AI in your organization.',
    date: '2024-03-10',
    author: 'John Smith'
  },
  {
    id: '3',
    title: 'AI Readiness: Preparing Your Organization',
    summary: 'Learn how to assess and improve your organization\'s AI readiness.',
    date: '2024-03-05',
    author: 'Emily Johnson'
  }
];

const ArticlesPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
      <header className="p-6 flex justify-between items-center">
        <Link to="/" className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          AI Strategy Suite
        </Link>
        <nav>
          <Link to="/" className="text-white hover:text-blue-300 mr-4">Home</Link>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">AI Strategy Articles</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles.map((article) => (
            <div key={article.id} className="bg-white bg-opacity-10 rounded-lg overflow-hidden transition-all duration-300 hover:bg-opacity-20">
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
                <p className="text-gray-300 mb-4">{article.summary}</p>
                <div className="flex justify-between text-sm text-gray-400">
                  <span>{article.author}</span>
                  <span>{article.date}</span>
                </div>
                <Link to={`/article/${article.id}`} className="mt-4 inline-block px-4 py-2 bg-blue-600 rounded-full hover:bg-blue-700 transition-colors duration-300">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </main>

      <footer className="mt-16 py-8 text-center text-gray-400">
        <p>&copy; 2024 AI Strategy Suite. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ArticlesPage;
