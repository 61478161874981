import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
      <header className="p-6">
        <Link to="/" className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          aiGuru Strategy Suite
        </Link>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <div className="bg-white bg-opacity-10 rounded-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="mb-4">
            Welcome to aiGuru Strategy Suite ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
          </p>

          <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
          <p className="mb-4">
            We collect personal information that you provide to us when you register for an account, express an interest in obtaining information about us or our products and services, or otherwise contact us.
          </p>

          <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
          <p className="mb-4">
            We use personal information collected via our website for various business purposes, including providing and maintaining our services, notifying you about changes, and improving our services.
          </p>

          <h2 className="text-2xl font-semibold mb-4">4. Disclosure of Your Information</h2>
          <p className="mb-4">
            We may share your personal information with service providers, in business transfers, or with your consent.
          </p>

          <h2 className="text-2xl font-semibold mb-4">5. Your Data Protection Rights Under the CCPA</h2>
          <p className="mb-4">
            If you are a California resident, you have certain data protection rights under the California Consumer Privacy Act (CCPA).
          </p>

          <h2 className="text-2xl font-semibold mb-4">6. Data Security</h2>
          <p className="mb-4">
            We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process.
          </p>

          <h2 className="text-2xl font-semibold mb-4">7. Data Retention</h2>
          <p className="mb-4">
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law.
          </p>

          <h2 className="text-2xl font-semibold mb-4">8. Changes to This Privacy Policy</h2>
          <p className="mb-4">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this Privacy Policy, please contact us via Contact Us page.
          </p>
        </div>
      </main>

      <footer className="mt-16 py-8 text-center text-gray-400">
        <p>&copy; 2024 aiGuru Strategy Suite. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;
