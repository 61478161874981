import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfUsePage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-800 text-white">
      <header className="p-6">
        <Link to="/" className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          aiGuru Strategy Suite
        </Link>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Terms of Use</h1>
        <div className="bg-white bg-opacity-10 rounded-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">1. Agreement to Terms</h2>
          <p className="mb-4">
            By accessing and using the aiGuru Strategy Suite (the "Service"), you agree to be bound by these Terms of Use ("Terms"). If you don't agree to these Terms, do not use the Service.
          </p>

          <h2 className="text-2xl font-semibold mb-4">2. Changes to Terms or Service</h2>
          <p className="mb-4">
            We reserve the right to modify these Terms at any time. We'll provide notice of any material changes by posting the updated Terms on this page with a new effective date.
          </p>

          <h2 className="text-2xl font-semibold mb-4">3. Accessing the Service</h2>
          <p className="mb-4">
            We reserve the right to withdraw or amend this Service, and any service or material we provide via the Service, in our sole discretion without notice.
          </p>

          <h2 className="text-2xl font-semibold mb-4">4. User Accounts</h2>
          <p className="mb-4">
            When you create an account with us, you must provide accurate, complete, and current information at all times. You are responsible for safeguarding your password.
          </p>

          <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property Rights</h2>
          <p className="mb-4">
            The Service and its entire contents are owned by aiGuru Strategy Suite and are protected by copyright, trademark, and other laws.
          </p>

          <h2 className="text-2xl font-semibold mb-4">6. Prohibited Uses</h2>
          <p className="mb-4">
            You may use the Service only for lawful purposes and in accordance with these Terms. Prohibited uses include violating laws, transmitting spam, impersonating others, and infringing on others' rights.
          </p>

          <h2 className="text-2xl font-semibold mb-4">7. Disclaimer of Warranties</h2>
          <p className="mb-4">
            THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND.
          </p>

          <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
          <p className="mb-4">
            THE COMPANY WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE.
          </p>

          <h2 className="text-2xl font-semibold mb-4">9. Indemnification</h2>
          <p className="mb-4">
            You agree to defend, indemnify, and hold harmless the Company from any claims arising out of your violation of these Terms or your use of the Service.
          </p>

          <h2 className="text-2xl font-semibold mb-4">10. Governing Law and Jurisdiction</h2>
          <p className="mb-4">
            These Terms shall be governed by and construed in accordance with the laws of [Your State].
          </p>

          <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
          <p className="mb-4">
	    If you have any questions about this Privacy Policy, please contact us via Contact Us page.
          </p>
        </div>
      </main>

      <footer className="mt-16 py-8 text-center text-gray-400">
        <p>&copy; 2024 aiGuru Strategy Suite. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsOfUsePage;
