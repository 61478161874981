import React, { useState, useEffect } from 'react';
import { generateClient } from '@aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';
import { listEthicalScenarios, listUserEthicalResponses } from '../graphql/queries';
import { createUserEthicalResponse } from '../graphql/mutations';
import { EthicalScenario, UserEthicalResponse } from '../API';
import { AlertCircle, CheckCircle, XCircle, ArrowRight } from 'lucide-react';

const client = generateClient();

const EthicalAISimulator: React.FC = () => {
  const [scenarios, setScenarios] = useState<EthicalScenario[]>([]);
  const [currentScenarioIndex, setCurrentScenarioIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [userResponses, setUserResponses] = useState<UserEthicalResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchScenarios();
    fetchUserResponses();
  }, []);


interface EthicalScenario {
  id: string;
  description: string;
  options: string[];
  correctOption: number;
  explanation: string;
  createdAt?: string;  // Now optional
  updatedAt?: string;  // Now optional
}


function detailedLog(data: any, prefix: string = '') {
  if (typeof data === 'object' && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        console.log(`${prefix}${key}:`);
        detailedLog(value, prefix + '  ');
      } else {
        console.log(`${prefix}${key}: ${value}`);
      }
    });
  } else {
    console.log(`${prefix}${data}`);
  }
}

async function fetchScenarios() {
  try {
    console.log('Fetching scenarios...');
    const scenariosData = await client.graphql({ 
      query: listEthicalScenarios
    });
    console.log('Raw scenarios data:', scenariosData);
    
    if (scenariosData.data?.listEthicalScenarios?.items) {
      const fetchedScenarios = scenariosData.data.listEthicalScenarios.items.map((item: any) => ({
        ...item,
        createdAt: item.createdAt || null,
        updatedAt: item.updatedAt || null
      })) as EthicalScenario[];
      
      console.log('Processed scenarios:', fetchedScenarios);
      
      if (fetchedScenarios.length === 0) {
        setError('No scenarios available. Please try again later.');
      } else {
        setScenarios(fetchedScenarios);
      }
    } else {
      throw new Error('No data returned from listEthicalScenarios query');
    }
    setLoading(false);
  } catch (err) {
    console.error('Error fetching scenarios:', err);
    if (err instanceof Error) {
      console.error('Error message:', err.message);
      console.error('Error stack:', err.stack);
    }
    setError('Failed to fetch scenarios. Please try again.');
    setLoading(false);
  }
}
  async function fetchUserResponses() {
    try {
      const { username } = await getCurrentUser();
      const userResponsesData = await client.graphql({
        query: listUserEthicalResponses,
        variables: { filter: { userId: { eq: username } } }
      });
      setUserResponses(userResponsesData.data.listUserEthicalResponses.items);
    } catch (err) {
      console.error('Error fetching user responses:', err);
    }
  }

  async function handleOptionSelect(optionIndex: number) {
    if (!scenarios[currentScenarioIndex]) return;

    setSelectedOption(optionIndex);
    const currentScenario = scenarios[currentScenarioIndex];
    setIsCorrect(optionIndex === currentScenario.correctOption);

    try {
      const { username } = await getCurrentUser();
      await client.graphql({
        query: createUserEthicalResponse,
        variables: {
          input: {
            userId: username,
            scenarioId: currentScenario.id,
            selectedOption: optionIndex,
            createdAt: new Date().toISOString()
          }
        }
      });
      await fetchUserResponses();
    } catch (err) {
      console.error('Error saving user response:', err);
      setError('Failed to save your response. Please try again.');
    }
  }

  function handleNextScenario() {
    setCurrentScenarioIndex((prevIndex) => (prevIndex + 1) % scenarios.length);
    setSelectedOption(null);
    setIsCorrect(null);
  }

console.log('Render state:', { loading, error, scenariosLength: scenarios.length });

if (loading) {
  return <div className="text-center py-8">Loading scenarios...</div>;
}

if (error) {
  console.error('Rendering error state:', error);
  return (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
      <p className="font-bold">Error</p>
      <p>{error}</p>
    </div>
  );
}

if (scenarios.length === 0) {
  console.log('No scenarios available');
  return <div className="text-center py-8">No scenarios available. Please try again later.</div>;
}

console.log('Rendering scenario:', scenarios[currentScenarioIndex]);


  const currentScenario = scenarios[currentScenarioIndex];

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Ethical AI Simulator</h2>
      <div className="bg-white bg-opacity-10 rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold mb-4">Scenario {currentScenarioIndex + 1}</h3>
        <p className="mb-4">{currentScenario.description}</p>
        <div className="space-y-4">
          {currentScenario.options.map((option, index) => (
            <button
              key={index}
              className={`w-full p-4 text-left rounded-lg transition-colors duration-300 ${
                selectedOption === index
                  ? isCorrect
                    ? 'bg-green-600 text-white'
                    : 'bg-red-600 text-white'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => handleOptionSelect(index)}
              disabled={selectedOption !== null}
            >
              {option}
            </button>
          ))}
        </div>
        {selectedOption !== null && (
          <div className={`mt-6 p-4 rounded-lg ${isCorrect ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            <p className="font-bold flex items-center">
              {isCorrect ? (
                <>
                  <CheckCircle className="mr-2" />
                  Correct!
                </>
              ) : (
                <>
                  <XCircle className="mr-2" />
                  Incorrect.
                </>
              )}
            </p>
            <p className="mt-2">{currentScenario.explanation}</p>
          </div>
        )}
        <button
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-300 flex items-center mx-auto"
          onClick={handleNextScenario}
        >
          Next Scenario
          <ArrowRight className="ml-2" />
        </button>
      </div>
      <div className="bg-white bg-opacity-10 rounded-lg p-6">
        <h3 className="text-2xl font-semibold mb-4">Your Performance</h3>
        <p>Total Scenarios Attempted: {userResponses.length}</p>
        <p>
          Correct Responses:{' '}
          {userResponses.filter((response) => {
            const scenario = scenarios.find((s) => s.id === response.scenarioId);
            return scenario && response.selectedOption === scenario.correctOption;
          }).length}
        </p>
      </div>
    </div>
  );
};

export default EthicalAISimulator;
